import { gql } from "graphql-request";

export const PROMOTION_SETTINGS_FIND_BY_COUPON = gql`
  query PromotionSettingsFindByCoupon($findByCouponInput: FindByCouponInput!) {
    PromotionSettingsFindByCoupon(findByCouponInput: $findByCouponInput) {
      id
      unique_id
      state_uuid
      name
      description
      start_date
      end_date
      status
      promotion_category
      type
      available_for
      use_auto
      priority
      purchase_condition
      limit_amount
      item_condition {
        id
        item_unique_id
        item_id
        item_name
        item_sku_name
        qty
        unit
        uom_id
        uom_name_th
        uom_name_en
      }
      item_group {
        type
        name
      }
      receive_type
      amount_per_item
      redeem_item {
        id
        item_unique_id
        item_id
        item_name
        item_sku_name
        qty
        unit
        uom_id
        uom_name_th
        uom_name_en
      }
      coupon_type
      coupon_code
      customer_eligibility
      customer_group_name
      customer_specific {
        id
        unique_id
        taxpayer_id
        source
        type
        zone
        sales_channel
        group
        last_updated_date
      }
      sales_channel_type
      sales_channel_details
      usage_limit_type
      usage_limit
      created_by
      last_updated_date
      created_date
      available_amount
    }
  }
`;
