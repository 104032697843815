import * as Yup from "yup";
import dayjs from "dayjs";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";
import { Tracability } from "../../../../generated/inventory";

export const deliveryOrderSchema: IDeliveryOrder = {
  unique_id: "",
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  issue_date: dayjs(),
  exported_date: dayjs(),
  delivery_date: dayjs(),
  last_updated_by: null,
  created_date: dayjs(),
  created_by: null,
  reference_no: "",
  reference_document_list: [],
  related_user_list: [],
  delivery_type: "",
  tracking_no: "",
  customer_unique_id: "",
  customer_details: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address_name: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  tag_list: [],
  trace_entry_list: [],
};

const goodsIssueTraceValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่นำออก")
    .required("กรุณาระบุจำนวนที่นำออก")
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
    ),
});

const goodsIssueSerialValidation = Yup.object().shape({
  stock_qty: Yup.number().nullable(),
  qty: Yup.number()
    .when("tracability", (tracability: any) =>
      tracability && tracability[0] === Tracability.Normal
        ? Yup.number()
            .transform((value) =>
              isNaN(value) || value === null || value === undefined ? 0 : value
            )
            .min(1, "กรุณาระบุจำนวนที่จะนำออก")
            .required("กรุณาระบุจำนวนที่จะนำออก")
        : Yup.number().nullable()
    )
    .when(
      "stock_qty",
      (value: any, schema) =>
        (value || value === 0) &&
        schema.max(value, "กรุณาระบุจำนวนนำออกไม่เกินจำนวนในคลัง")
    ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsIssueTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const deliveryOrderValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date()
    .required("กรุณาระบุวันที่ออกเอกสาร")
    .typeError("กรุณาระบุวันที่ออกเอกสารให้ถูกต้อง"),
  exported_date: Yup.date()
    .required("กรุณาระบุวันที่นำออก")
    .typeError("กรุณาระบุวันที่นำออกให้ถูกต้อง"),
  delivery_date: Yup.date()
    .required("กรุณาระบุวันกำหนดส่งของ")
    .typeError("กรุณาระบุวันกำหนดส่งของให้ถูกต้อง"),
  customer_details: Yup.object().shape({
    name: Yup.string().required("กรุณาระบุลูกค้า"),
    identity_no: Yup.string().required("กรุณาเลือกเลขประจำตัวผู้เสียภาษี"),
  }),
  // trace_entry_list: Yup.array()
  //   .of(goodsIssueSerialValidation)
  //   .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  delivery_type: Yup.string().required("กรุณาระบุประเภทการจัดส่ง"),
  branch_id: Yup.number().required("กรุณาระบุสาขา").typeError("กรุณาระบุสาขา"),
  destination_warehouse_id: Yup.number()
    .required("กรุณาระบุคลัง")
    .typeError("กรุณาระบุคลัง"),
});
