import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import CustomerSpecificTable from "../../../../Table/Marketing/Promotion/CustomerSpecific";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import CheckedCustomerModal from "../../../../UI/Modal/CheckedCustomerModal";
import { useModal } from "../../../../../hooks/use-modal";
import { useState, useEffect } from "react";
import { Box, FormHelperText } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";

const PromotionSpecificCustomer = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();

  const [disabled] = useDisable();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const { t } = useTranslation();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "customer_specific",
  });

  const [customerIds, setCustomerIds] = useState<string[]>([]);
  const [customerIdsSnapshot, setCustomerIdsSnapshot] = useState<string[]>([]);

  useEffect(() => {
    const customerIdsFromField = fields.map((field) => field.unique_id);
    setCustomerIds(customerIdsFromField);
    setCustomerIdsSnapshot(customerIdsFromField);
  }, [fields]);

  const addContactHandler = (data: any) => {
    replace(data);
    closeModalHandler();
  };

  const onRemoveHandler = (index: number) => {
    remove(index);
  };

  const { modal, closeModalHandler, openModalHandler } = useModal();

  return (
    <>
      <Box mt={1}>
        <CustomerSpecificTable
          rowData={fields}
          onRemoveHandler={onRemoveHandler}
        />
        {!disabled && !isApplied && (
          <CustomizedButton
            variant="outlined"
            title={`${t("button.add")}${t("contact.contact_type.customer")}`}
            onClick={openModalHandler}
            addIcon
          />
        )}
        {Boolean(errors.customer_specific) && (
          <FormHelperText error sx={{ mt: 1 }}>
            {errors.customer_specific?.message}
          </FormHelperText>
        )}
      </Box>
      <CheckedCustomerModal
        customerIds={customerIds}
        customerIdsSnapshot={customerIdsSnapshot}
        setCustomerIds={setCustomerIds}
        setCustomerIdsSnapshot={setCustomerIdsSnapshot}
        showSelectCustomer={modal}
        rowSelection="multiple"
        closeCustomerTable={closeModalHandler}
        finishCustomersSelect={addContactHandler}
      />
    </>
  );
};

export default PromotionSpecificCustomer;
