import { Control, Controller } from "react-hook-form";
import CustomizedRadioGroup from "../Custom/CustomizedRadioGroup";
import { RadioGroupProps, RadioProps } from "@mui/material";
import { IRadio } from "../../types/global";

type ExtendedRadioGroupProps = {
  control: Control<any>;
  name: string;
  row: RadioGroupProps["row"];
  radioLists: IRadio[];
  disabled: boolean;
  sx?: RadioProps["sx"];
  onChange?: RadioGroupProps["onChange"];
};

const ControlledRadioGroup = ({
  name,
  row,
  control,
  radioLists,
  disabled,
  sx,
  onChange,
}: ExtendedRadioGroupProps) => {
  return (
    <Controller
      key={`${name}-radio-group`}
      name={name}
      control={control}
      render={({ field }) => (
        <CustomizedRadioGroup
          {...field}
          radioList={radioLists}
          row={row}
          disabled={disabled}
          sx={sx}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) {
              onChange(e, e.target.value);
            }
          }}
        />
      )}
    />
  );
};

export default ControlledRadioGroup;
