import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { ISalesInvoice } from "../../../types/Sales/salesInvoice";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { Box, Grid, Typography } from "@mui/material";
import SalesDeductionTable from "../../../components/Table/Sales/Deduction";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "../../../utils/Formatter/Global";
import PaymentTabSummary from "../../../components/Form/Sales/Payment/PaymentTabSummary";
import SalesPaymentTable from "../../../components/Table/Sales/Payment";
import PaymentModal from "../../../components/UI/Modal/PaymentModal";
import DeductionModal from "../../../components/UI/Modal/DeductionModal";
import { useModal } from "../../../hooks/use-modal";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ActivityLogDocumentType,
  ActivityType,
  SalesInvoiceFindUniqueQuery,
  SalesReferenceDocumentType,
  useSalesInvoiceFindUniqueQuery,
  useSalesInvoiceUpdateMutation,
} from "../../../generated/sales";
import { useParams } from "react-router-dom";
import { ISalesDeduction, ISalesPaymentChannel } from "../../../types/Sales";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useStateContext } from "../../../contexts/auth-context";

type Props = {
  paymentList: FieldArrayWithId<ISalesInvoice, "payment_list", "id">[];
  deductionList: FieldArrayWithId<
    ISalesInvoice,
    "deduction_document_list",
    "id"
  >[];
};

const PaymentTab = ({ paymentList, deductionList }: Props) => {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const graphqlClient = createGraphQLClientWithMiddleware("sales");

  const { createActivityLog } = useActivityLog();

  const { refetch } =
    useSalesInvoiceFindUniqueQuery<SalesInvoiceFindUniqueQuery>(
      graphqlClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const { mutateAsync: update } =
    useSalesInvoiceUpdateMutation<Error>(graphqlClient);

  const paymentAmount: number = paymentList.reduce(
    (acc, channel) => acc + (channel.payment_amount || 0),
    0
  );

  const deductionAmount: number = deductionList.reduce(
    (acc, deduct) => acc + (deduct.deduction_amount || 0),
    0
  );

  const withholdingTaxAmount: number = paymentList.reduce(
    (acc, channel) => acc + (channel.withholding_tax_amount || 0),
    0
  );

  const status = watch("aggrid_status");
  const netAmount = watch("net_amount");

  const {
    modal: paymentModal,
    openModalHandler: openPaymentModalHandler,
    closeModalHandler: closePaymentModal,
  } = useModal();

  const {
    modal: deductionModal,
    openModalHandler: openDeductionModalHandler,
    closeModalHandler: closeDeductionModalHandler,
  } = useModal();

  const onDeletePaymentHandler = async (deletedId?: number) => {
    try {
      const {
        price_vat_type,
        currency,
        deduction_document_list,
        net_amount,
        branch_id,
        unique_id,
        aggrid_status,
      } = getValues();

      const removedPaymentList = paymentList.filter(
        (payment) => payment.id !== deletedId
      );

      const formatPaymentList = removedPaymentList.map(
        ({
          id,
          payment_channel_unique_id_name,
          ...otherItem
        }: ISalesPaymentChannel) => ({
          ...otherItem,
          branch_id: branch_id,
          reference_document_type: SalesReferenceDocumentType.SalesInvoice,
          reference_unique_id: unique_id,
        })
      );

      const paymentAmount: number =
        formatPaymentList && formatPaymentList.length > 0
          ? formatPaymentList.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.payment_amount || 0),
              0
            )
          : 0;

      const paymentWhtAmount: number =
        formatPaymentList && formatPaymentList.length > 0
          ? formatPaymentList.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.withholding_tax_amount || 0),
              0
            )
          : 0;

      const deductionAmount: number =
        deduction_document_list && deduction_document_list.length > 0
          ? deduction_document_list.reduce(
              (acc: number, deduct: ISalesDeduction) =>
                acc + (deduct.deduction_amount || 0),
              0
            )
          : 0;

      const paidNetAmount: number = paymentAmount + deductionAmount;

      const paidTotalAmount: number = paidNetAmount + paymentWhtAmount;

      const updatedMainStatus =
        parseFloat(paidTotalAmount.toFixed(2)) === 0
          ? "wait_payment"
          : parseFloat(paidTotalAmount.toFixed(2)) <
            parseFloat(net_amount.toFixed(2))
          ? "partially_payment"
          : "fully_payment";

      const { SalesInvoiceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },

        input: {
          currency,
          price_vat_type,
          main_status: updatedMainStatus,
          payment_list: formatPaymentList,
          paid_net_amount: paidNetAmount,
          payment_total_amount: paymentAmount,
          deduction_total_amount: deductionAmount,
          paid_total_amount: paidTotalAmount,
          last_updated_by: {
            user_id: authUser!.id,
            user_unique_id: authUser!.unique_id,
            first_name: authUser!.first_name,
            last_name: authUser!.last_name,
            email: authUser!.email,
            img_url: authUser!.img_url,
          },
        },
      });
      enqueueSnackbar(`ลบการรับชำระสำเร็จ`, {
        variant: "success",
      });
      await refetch();

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesInvoice,
        reference_id: SalesInvoiceUpdate?.id,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: aggrid_status,
          curr_status: SalesInvoiceUpdate.main_status,
        },
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`ลบการรับชำระไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const onDeleteDeductionHandler = async (deletedId?: number) => {
    try {
      const {
        price_vat_type,
        currency,
        payment_list,
        net_amount,
        branch_id,
        unique_id,
        aggrid_status,
      } = getValues();
      const removedDeductionList = deductionList.filter(
        (deduction) => deduction.id !== deletedId
      );

      const formatDeductionDocumentList = removedDeductionList.map(
        ({ id, ...otherItem }: ISalesDeduction) => ({
          ...otherItem,
          branch_id: branch_id,
          reference_document_type: SalesReferenceDocumentType.SalesInvoice,
          reference_unique_id: unique_id,
        })
      );

      const paymentAmount: number =
        payment_list && payment_list.length > 0
          ? payment_list.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.payment_amount || 0),
              0
            )
          : 0;

      const paymentWhtAmount: number =
        payment_list && payment_list.length > 0
          ? payment_list.reduce(
              (acc: number, channel: ISalesPaymentChannel) =>
                acc + (channel.withholding_tax_amount || 0),
              0
            )
          : 0;

      const deductionAmount: number =
        formatDeductionDocumentList && formatDeductionDocumentList.length > 0
          ? formatDeductionDocumentList.reduce(
              (acc: number, deduct: ISalesDeduction) =>
                acc + (deduct.deduction_amount || 0),
              0
            )
          : 0;

      const paidNetAmount: number = paymentAmount + deductionAmount;

      const paidTotalAmount: number = paidNetAmount + paymentWhtAmount;

      const updatedMainStatus =
        parseFloat(paidTotalAmount.toFixed(2)) === 0
          ? "wait_payment"
          : parseFloat(paidTotalAmount.toFixed(2)) <
            parseFloat(net_amount.toFixed(2))
          ? "partially_payment"
          : "fully_payment";

      const { SalesInvoiceUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: {
          currency,
          price_vat_type,
          main_status: updatedMainStatus,
          deduction_document_list: formatDeductionDocumentList,
          paid_net_amount: paidNetAmount,
          payment_total_amount: paymentAmount,
          deduction_total_amount: deductionAmount,
          paid_total_amount: paidTotalAmount,
          last_updated_by: {
            user_id: authUser!.id,
            user_unique_id: authUser!.unique_id,
            first_name: authUser!.first_name,
            last_name: authUser!.last_name,
            email: authUser!.email,
            img_url: authUser!.img_url,
          },
        },
      });
      enqueueSnackbar(`ลบการรับชำระสำเร็จ`, {
        variant: "success",
      });
      await refetch();

      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.SalesInvoice,
        reference_id: SalesInvoiceUpdate?.id,
        activity_detail: {
          secondary_operation: ActivityType.Edit,
          prev_status: aggrid_status,
          curr_status: SalesInvoiceUpdate.main_status,
        },
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`ลบการรับชำระไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CustomizedBox margin={0}>
        <Typography fontWeight={600} mb={2}>
          {t("sales.deduction.index")}
        </Typography>
        <SalesDeductionTable
          data={deductionList || []}
          documentType="sales_invoice"
          onDeleteHandler={onDeleteDeductionHandler}
        />
        {(status === "wait_payment" || status === "partially_payment") && (
          <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
            <CustomizedButton
              title={"ตัดชำระ"}
              variant={"outlined"}
              onClick={openDeductionModalHandler}
            />
          </Box>
        )}
        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                gap: 3,
                py: 0.5,
                bgcolor: (theme) => theme.palette.primary.light,
              }}
            >
              <>
                <Typography
                  variant="subtitle1"
                  textAlign={"right"}
                  flex={2}
                  fontWeight={600}
                  color={"primary.main"}
                >
                  {t("sales.deduction.deduction_amount")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    minWidth: 150,
                    textAlign: "right",
                    mr: 2,
                    flex: 1,
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  {numberFormatter(deductionAmount)}
                </Typography>
              </>
              <Typography
                variant="subtitle1"
                sx={{ ml: 2, mr: 4, fontWeight: 600, color: "primary.main" }}
              >
                บาท
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomizedBox>
      <CustomizedBox>
        <Typography fontWeight={600} mb={2}>
          ช่องทางการชำระเงิน
        </Typography>
        <SalesPaymentTable
          data={paymentList}
          documentType="sales_invoice"
          onDeleteHandler={onDeletePaymentHandler}
        />
        {(status === "wait_payment" || status === "partially_payment") && (
          <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
            <CustomizedButton
              title={"รับชำระ"}
              variant={"outlined"}
              onClick={openPaymentModalHandler}
            />
          </Box>
        )}
        <Grid container maxWidth={984} spacing={0}>
          <Grid item xs={12} sm={12} md={6.5} />
          <Grid item xs={12} sm={12} md={5.5}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                gap: 3,
                py: 0.5,
                bgcolor: (theme) => theme.palette.primary.light,
              }}
            >
              <>
                <Typography
                  variant="subtitle1"
                  textAlign={"right"}
                  flex={2}
                  fontWeight={600}
                  color={"primary.main"}
                >
                  {t("sales.payment.payment_amount")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    minWidth: 150,
                    textAlign: "right",
                    mr: 2,
                    flex: 1,
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  {numberFormatter(paymentAmount)}
                </Typography>
              </>
              <Typography
                variant="subtitle1"
                sx={{ ml: 2, mr: 4, fontWeight: 600, color: "primary.main" }}
              >
                บาท
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomizedBox>
      <CustomizedBox margin={0}>
        <PaymentTabSummary
          paymentAmount={paymentAmount}
          deductionAmount={deductionAmount}
          withholdingTaxAmount={withholdingTaxAmount}
          netAmount={netAmount}
        />
      </CustomizedBox>
      <PaymentModal open={paymentModal} closeModalHandler={closePaymentModal} />
      <DeductionModal
        open={deductionModal}
        closeModalHandler={closeDeductionModalHandler}
      />
    </>
  );
};

export default PaymentTab;
