import { gql } from "graphql-request";

export const ITEM_SKU_DETAIL_AGGRID = gql`
  query ItemSkuDetailsFindManyAggrid($aggridInput: AnyAggridInput) {
    itemSkuDetailsFindManyAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        sku_name
        item_unique_id
        barcode
        sale_price
        special_price
        purchase_standard_price
        status
        img_url
        total_sku_qty
        item_sku_qty {
          id
          stock_qty
          available_qty
          purchase_ordered_qty
          transit_qty
          sale_committed_qty
        }
        item {
          unique_id
          type
          img_url
          description
          purchase_standard_price
          sale_price
          special_price
          stock_uom {
            id
            unique_id
            name_th
          }
          uom_group {
            base_uom {
              id
              unique_id
              name_th
            }
            convertable_uom_list {
              id
              name_th
              unique_id
            }
            uom_conversion_list {
              id
              base_uom_rate
              target_uom {
                id
                name_th
                unique_id
              }
              target_uom_rate
              target_uom_id
            }
          }
          name
          model
          seller_unique_id
          tag_list {
            name
          }
          category_group
          main_category
          sub_category
          brand
          segment
          series
          tracability
          delivery_attribute {
            volume
            weight
          }
          sale_vat_type
          sales_uom {
            unique_id
            name_th
            id
          }
          stock_uom {
            unique_id
            name_th
            id
          }
        }
        status
      }
      count
    }
  }
`;

export const ITEM_VIEW = gql`
  query ItemsView($aggridInput: AnyAggridInput) {
    ItemsView(aggridInput: $aggridInput) {
      results {
        sku_name
        created_date
        img_url
        seller_unique_id
        item_id
        item_unique_id
        name
        type
        model
        tracability
        barcode
        description
        tag_list
        category_group
        main_category
        sub_category
        brand
        segment
        series
        stock_qty
        stock_uom_id
        stock_uom_name_th
        stock_uom_unique_id
        sales_uom_id
        sales_uom_name_th
        sales_uom_unique_id
        purchase_uom_id
        purchase_uom_name_th
        purchase_uom_unique_id
        deliver_uom_id
        deliver_uom_name_th
        deliver_uom_unique_id
        purchase_ordered_qty
        sale_committed_qty
        available_qty
        transit_qty
        status
        is_stockable
        purchase_standard_price
        min_purchase_request_qty
        purchase_vat_type
        sale_price
        special_price
        sale_vat_type
        delivery_attribute
        uom_group
        bundle_item_detail_list
        item_sku_qty_list {
          id
          stock_qty
          available_qty
          purchase_ordered_qty
          transit_qty
          sale_committed_qty
        }
        total_sku_qty
      }
      count
    }
  }
`;

export const ITEMS_COUNT_AGGRID = gql`
  query ItemsView($aggridInput: AnyAggridInput) {
    ItemsView(aggridInput: $aggridInput) {
      count
      results {
        sku_name
      }
    }
  }
`;

export const ITEM_SKU_DETAIL_COUNT = gql`
  query ItemSkuDetailsFindManyAggrid($aggridInput: AnyAggridInput) {
    itemSkuDetailsFindManyAggrid(aggridInput: $aggridInput) {
      count
    }
  }
`;

export const GOODS_RECEIVE_VIEW = gql`
  query GoodsReceivesView($aggridInput: AnyAggridInput) {
    GoodsReceivesView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        posted_date
        reference_unique_id_list
        branch_id
        branch_name
        warehouse_name
        type
        created_by
        img_url
        remark
        main_status
        sub_status
        aggrid_status
      }
    }
  }
`;

export const GOODS_ISSUE_VIEW = gql`
  query GoodsIssuesView($aggridInput: AnyAggridInput) {
    GoodsIssuesView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        posted_date
        reference_unique_id
        branch_id
        branch_name
        warehouse_name
        type
        created_by
        img_url
        remark
        main_status
        sub_status
        aggrid_status
      }
    }
  }
`;

export const GOODS_RETURN_VIEW = gql`
  query GoodsReturnsView($aggridInput: AnyAggridInput) {
    GoodsReturnsView(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        created_date
        posted_date
        reference_unique_id
        branch_id
        branch_name
        warehouse_name
        type
        created_by
        img_url
        remark
        main_status
        sub_status
        aggrid_status
      }
    }
  }
`;

export const GOODS_TRANSFER_VIEW = gql`
  query GoodsTransferView($aggridInput: AnyAggridInput!, $branchId: Int) {
    GoodsTransferView(aggridInput: $aggridInput, branchId: $branchId) {
      results {
        id
        unique_id
        created_date
        posted_date
        source_branch_id
        source_branch_name
        source_warehouse_name
        destination_branch_name
        destination_warehouse_name
        created_by
        img_url
        remark
        main_status
        aggrid_status
      }
      count
    }
  }
`;

export const GOODS_ADJUSTMENT_VIEW = gql`
  query GoodsAdjustView($aggridInput: AnyAggridInput!) {
    GoodsAdjustView(aggridInput: $aggridInput) {
      results {
        id
        unique_id
        created_date
        posted_date
        branch_id
        branch_name
        warehouse_name
        created_by
        img_url
        remark
        main_status
        aggrid_status
      }
      count
    }
  }
`;

export const TRACE_ENTRY_VIEW = gql`
  query TraceEntriesView($aggridInput: AggridTemplateInput) {
    TraceEntriesView(aggridInput: $aggridInput) {
      results {
        unique_id
        created_date
        posted_date
        reference_unique_id
        reference_id
        source_branch_id
        source_branch_name
        source_warehouse_id
        source_warehouse_name
        source_bin_location_id
        source_bin_location_name
        destination_branch_id
        destinaton_branch_name
        destination_warehouse_id
        destination_warehouse_name
        destination_bin_location_id
        destination_bin_location_name
        item_unique_id
        sku_name
        item_name
        lot_date
        serial_no
        barcode
        qty
        uom_id
        uom_unique_id
        uom_name_th
        uom_name_en
        scanned_by
        scanned_by_object
        type
        status
      }
      count
    }
  }
`;

export const CURRENT_STOCK_BY_BRANCH_VIEW = gql`
  query ItemSkuQtysByBranchView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByBranchView(aggridInput: $aggridInput) {
      count
      results {
        item_id
        item_unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        stock_qty
        available_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const CURRENT_STOCK_BY_WAREHOUSE_VIEW = gql`
  query ItemSkuQtysByWarehouseView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByWarehouseView(aggridInput: $aggridInput) {
      results {
        item_id
        item_unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        stock_qty
        available_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
      count
    }
  }
`;

export const CURRENT_STOCK_BY_LOCATION_VIEW = gql`
  query ItemSkuQtysByBinLocationView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByBinLocationView(aggridInput: $aggridInput) {
      count
      results {
        item_id
        item_unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_id
        bin_name
        stock_qty
        available_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const CURRENT_STOCK_BY_SERIAL_NUMBER_VIEW = gql`
  query ItemSkuQtysByBarcodeView($aggridInput: AnyAggridInput) {
    ItemSkuQtysByBarcodeView(aggridInput: $aggridInput) {
      count
      results {
        item_id
        item_unique_id
        sku_name
        item_name
        branch_id
        branch_unique_id
        branch_name
        warehouse_id
        warehouse_unique_id
        warehouse_name
        bin_id
        bin_name
        serial_no
        barcode
        lot_date
        manufactured_date
        expired_date
        stock_qty
        available_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const ITEM_CURRENT_STOCK_BY_LOCATION_REPORT_VIEW = gql`
  query ItemCurrentStockByLocationReportViews($aggridInput: AnyAggridInput) {
    ItemCurrentStockByLocationReportViews(aggridInput: $aggridInput) {
      count
      results {
        item_unique_id
        sku_name
        item_name
        branch_id
        branch_name
        warehouse_id
        warehouse_name
        bin_id
        bin_name
        available_qty
        stock_qty
        purchase_ordered_qty
        transit_qty
        sale_committed_qty
      }
    }
  }
`;

export const ITEM_PRICE_LIST_VIEW = gql`
  query ItemPriceListViewAggrid($aggridInput: AnyAggridInput) {
    ItemPriceListViewAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        type
        name
        sales_channel_id
        sales_channel_unique_id
        sales_channel_name_th
        sales_channel_name_en
        is_increase
        currency
        description
        is_active
        start_date
        end_date
        adjusted_price
      }
    }
  }
`;
