import { gql } from "graphql-request";

export const PROMOTION_AGGRID = gql`
  query PromotionSettingViewsAggrid($aggridInput: AnyAggridInput) {
    PromotionSettingViewsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        unique_id
        name
        description
        start_date
        end_date
        status
        promotion_category
        type
        available_for
        use_auto
        priority
        purchase_condition
        limit_amount
        receive_type
        amount_per_item
        coupon_type
        customer_eligibility
        customer_group_name
        sales_channel_type
        usage_limit_type
        usage_limit
        created_date
        created_by
        created_by_object
        last_updated_date
        available_amount
      }
    }
  }
`;

export const COUPON_AGGRID = gql`
  query CouponViewsAggrid($aggridInput: AnyAggridInput) {
    CouponViewsAggrid(aggridInput: $aggridInput) {
      results {
        id
        sequence
        code
        status
        created_date
        last_updated_date
      }
      count
      available
    }
  }
`;

export const AVAILABLE_PROMOTION_SETTINGS_AGGRID = gql`
  query PromotionSettingAvailableAggrid(
    $customInput: CustomAvailablePromotionInput!
    $aggridInput: AnyAggridInput
  ) {
    PromotionSettingAvailableAggrid(
      customInput: $customInput
      aggridInput: $aggridInput
    ) {
      count
      results {
        id
        unique_id
        name
        description
        start_date
        end_date
        status
        promotion_category
        type
        available_for
        use_auto
        priority
        purchase_condition
        limit_amount
        receive_type
        amount_per_item
        coupon_type
        customer_eligibility
        customer_group_name
        sales_channel_type
        usage_limit_type
        usage_limit
        created_by
        created_by_object
        created_date
        last_updated_date
        available_amount
        coupon_code
      }
    }
  }
`;
