import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FieldArrayWithId,
  UseFieldArrayUpdate,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useSnackbar } from "notistack";

import { Grid, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";

import { useDisable } from "../../../../hooks/use-disable";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";
import {
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";
import { goodsIssueTraceEntryFormatter } from "../../../../utils/Formatter/Inventory/GoodsIssue";

type Props = {
  fields: FieldArrayWithId<IDeliveryOrder, "trace_entry_list", "id">[];
  update: UseFieldArrayUpdate<IDeliveryOrder, "trace_entry_list">;
};

const ScanBarcode = ({ fields, update }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const { getValues, watch } = useFormContext<IDeliveryOrder>();

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
    },
  });

  const status = watch("aggrid_status");

  const watchWarehouseId = watch("destination_warehouse_id");

  const traceEntryList = watch("trace_entry_list");

  const graphQLClientWithHeaderItem = createGraphQLClientWithMiddleware("wms");

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetch();
    const { data: skuQtyData } = await refetchSkuQty();

    if (skuQtyData?.itemSkuQtys) {
      if (skuQtyData.itemSkuQtys.length > 0) {
        const filteredWarehouse = skuQtyData.itemSkuQtys.filter(
          (itemSkuQty) => itemSkuQty?.warehouse_id === watchWarehouseId
        );

        if (filteredWarehouse.length > 0) {
          const formatBinLocations = skuQtyData.itemSkuQtys.map((itemSku) => ({
            id: itemSku?.bin_id,
            name: itemSku?.bin_name,
            stock_qty: itemSku?.stock_qty,
            warehouse_id: itemSku?.warehouse_id,
          }));

          const filterBinLocations = formatBinLocations.filter(
            (bin) => bin.warehouse_id === watchWarehouseId
          );

          const traceEntries = data?.TraceEntriesFindManyAggrid?.results;
          const traceEntry = traceEntries
            ? traceEntries.find(
                (trace) => trace?.type === InventoryDocumentType.GoodsReceive
              )
            : null;
          if (traceEntry) {
            const traceEntryType = traceEntry as ITraceEntry;
            const formatTrace = goodsIssueTraceEntryFormatter(
              traceEntryType,
              filterBinLocations,
              authUser
            );
            if (formatTrace) {
              if (formatTrace.tracability === Tracability.Serial) {
                const foundExisting = traceEntryList.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );
                // update serial
                const currentTrace: ITraceEntry = getValues(
                  `trace_entry_list.${foundExisting}`
                );
                if (currentTrace && currentTrace.serial_list) {
                  const foundBarcode = currentTrace.serial_list.findIndex(
                    (serial) =>
                      serial.barcode ===
                      (watchBarcode("barcodeMobile")?.trim() ||
                        watchBarcode("barcode").trim())
                  );
                  if (foundBarcode === -1) {
                    const { document_item_qty, posted_qty, ...otherTrace } =
                      formatTrace;
                    const formatTraceWithDocument: ITraceEntry = {
                      ...otherTrace,
                      document_item_qty: currentTrace.document_item_qty,
                      posted_qty: currentTrace.posted_qty,
                    };
                    update(foundExisting, {
                      ...currentTrace,
                      serial_list: [
                        ...currentTrace.serial_list,
                        formatTraceWithDocument,
                      ],
                    });
                    enqueueSnackbar(
                      `สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`,
                      {
                        variant: "success",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  } else {
                    enqueueSnackbar(
                      `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                      {
                        variant: "error",
                        style: { whiteSpace: "pre-line" },
                      }
                    );
                  }
                }
              } else {
                // append new trace (normal view)
                const foundExisting = fields.findIndex(
                  (trace) => trace.item_unique_id === formatTrace.item_unique_id
                );

                const currentTrace: ITraceEntry = getValues(
                  `trace_entry_list.${foundExisting}`
                );
                const { document_item_qty, posted_qty, ...otherTrace } =
                  formatTrace;

                const formatTraceWithDocument: ITraceEntry = {
                  ...otherTrace,
                  document_item_qty: currentTrace.document_item_qty,
                  posted_qty: currentTrace.posted_qty,
                };

                if (currentTrace.status === "is_active") {
                  update(foundExisting, formatTraceWithDocument);
                  enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  });
                } else {
                  enqueueSnackbar(
                    `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                    {
                      variant: "error",
                      style: { whiteSpace: "pre-line" },
                    }
                  );
                }
              }
            }
          }
        } else {
          // Warehouse not match
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่", {
            variant: "error",
          });
        }
      } else {
        // Barcode not found
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    authUser,
    enqueueSnackbar,
    fields,
    getValues,
    refetch,
    refetchSkuQty,
    resetBarcode,
    update,
    watchBarcode,
    watchWarehouseId,
  ]);

  if (!id || status === "draft")
    return (
      <CustomizedBox>
        <Typography fontWeight={600} color={"primary.main"}>
          สแกน Barcode
        </Typography>
        <Grid container spacing={1.5} mt={1}>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ControlledTextField
              label="Scan Barcode"
              control={barcodeControl}
              name="barcode"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  onBarcodeSubmitHandler();
                }
              }}
              disabled={disabled || !watchWarehouseId}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    );
  else return <></>;
};

export default ScanBarcode;
