import { useEffect, useState } from "react";
import {
  CashSalesCreateInput,
  CashSalesFindUniqueQuery,
  CashSalesUpdateInput,
  SalesReferenceDocumentType,
  useCashSalesCreateMutation,
  useCashSalesFindUniqueQuery,
  useCashSalesUpdateMutation,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  cashSalesCreatePayloadFormatter,
  cashSalesQueryFormatter,
  cashSalesUpdatePayloadFormatter,
} from "../../../utils/Formatter/Sales/CashSales";
import { ICashSales } from "../../../types/Sales/cashSales";
import {
  ActivityLogDocumentType,
  ActivityType,
} from "../../../generated/general";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useSalesError } from "../../../hooks/Sales/use-sales-error";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSalesOption } from "../../../hooks/Sales/use-sales-option";
import { useActivityLog } from "../../../hooks/use-activity-log";
import CashSalesHeader from "../../../components/Form/Sales/CashSales/Header";
import CustomerInfo from "../../../components/Form/Sales/CustomerInfo";
import AddressInfo from "../../../components/Form/Sales/CustomerInfo/AddressInfo";
import SalesInfo from "../../../components/Form/Sales/SalesInfo";
import SalesItemList from "../../../components/Table/Sales/ItemList/SalesItemList";
import SalesFooter from "../../../components/Form/Sales/Footer";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useDisable } from "../../../hooks/use-disable";
import { useFormContext, useWatch } from "react-hook-form";
import { IExternalProps } from "../../../types/Sales";
import { useTranslation } from "react-i18next";
import { errorMessageFormatter } from "../../../utils/Global";
import dayjs from "dayjs";
import SalesPayment from "../../../components/Form/Sales/Payment/SalesPayment";
import { validateSalesItemQty } from "../../../utils/Formatter/Sales";
import { useStateContext } from "../../../contexts/auth-context";
import SalesPromotion from "../../../components/Form/Sales/Promotion";

const DocumentInfoTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const navigate = useNavigate();
  const { isLoading: isOptionLoading } = useSalesOption();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { createActivityLog } = useActivityLog();
  const [disabled, setDisabled] = useDisable();
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();

  const {
    state: { authUser },
  } = useStateContext();

  const [netAmount, setNetAmount] = useState<number | null | undefined>(0);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = useFormContext<ICashSales>();
  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const watchNetAmount: number =
    useWatch({
      control,
      name: "net_amount",
    }) || 0;

  const paidTotalAmount: number =
    useWatch({
      control,
      name: "paid_total_amount",
    }) || 0;

  const remainingPayment =
    parseFloat(watchNetAmount.toFixed(2)) -
    parseFloat(paidTotalAmount.toFixed(2));

  const { data, isLoading, isSuccess, refetch } =
    useCashSalesFindUniqueQuery<CashSalesFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  useEffect(() => {
    setIsLoadingData(true);
    if (isSuccess) {
      const { CashSalesFindUnique } = data;
      setNetAmount(CashSalesFindUnique.net_amount);
      const getCashSalesData = async () => {
        const formattedCashSales = await cashSalesQueryFormatter(
          CashSalesFindUnique
        );
        reset(formattedCashSales);
        setIsLoadingData(false);
      };
      getCashSalesData();
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    if (id && status !== "draft") {
      setDisabled(true);
    }

    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        issue_date: dayjs(),
        due_date: dayjs().add(state.credit_day || 0, "days"),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useCashSalesCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useCashSalesUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: ICashSales) => {
    if (!id) {
      try {
        const formatData = cashSalesCreatePayloadFormatter(data, "draft");
        const { CashSalesCreate } = await create({
          createInput: {
            ...formatData,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          } as CashSalesCreateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/cash-sales/${CashSalesCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.CashSales,
            reference_id: CashSalesCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: CashSalesCreate.id,
                unique_id: CashSalesCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CashSales,
          reference_id: CashSalesCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
            updated_fields: {
              net_amount: CashSalesCreate.net_amount,
            },
          },
        });
      } catch (err) {
        console.error(err);
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = cashSalesUpdatePayloadFormatter(data, "draft");
        const { CashSalesUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: {
            ...formatData,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          } as CashSalesUpdateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);

        if (formattedDirtyFields.length > 0) {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ActivityLogDocumentType.CashSales,
            reference_id: CashSalesUpdate?.id,
            activity_detail: {
              updated_fields: {
                net_amount:
                  netAmount?.toFixed(2) ===
                  CashSalesUpdate.net_amount?.toFixed(2)
                    ? undefined
                    : CashSalesUpdate.net_amount,
              },
            },
          });
        }
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const finishHandler = async (data: ICashSales) => {
    if (!id) {
      try {
        const formatData = cashSalesCreatePayloadFormatter(data, "finished");
        if (
          formatData.reference_document_list &&
          formatData.reference_document_list.length > 0
        ) {
          const isValid = await validateSalesItemQty(
            formatData.item_list,
            SalesReferenceDocumentType.CashSales
          );
          if (!isValid) {
            enqueueSnackbar("จำนวนสินค้าเกินจำนวนจากใบสั่งขาย", {
              variant: "error",
            });
            return;
          }
        }
        const { CashSalesCreate } = await create({
          createInput: {
            ...formatData,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          } as CashSalesCreateInput,
        });
        enqueueSnackbar(`${t("button.send")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/sales/cash-sales/${CashSalesCreate.id}`);
        if (state && state.copied_id) {
          await createActivityLog({
            activity_type: ActivityType.Copy,
            document_type: ActivityLogDocumentType.CashSales,
            reference_id: CashSalesCreate?.id,
            activity_detail: {
              copied_from: {
                id: state.copied_id,
                unique_id: state.copied_unique_id,
              },
              copied_to: {
                id: CashSalesCreate.id,
                unique_id: CashSalesCreate.unique_id,
              },
            },
          });
        }
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CashSales,
          reference_id: CashSalesCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "finished",
            updated_fields: {
              net_amount: CashSalesCreate.net_amount,
            },
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(formatError || `${t("button.send")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = cashSalesUpdatePayloadFormatter(data, "finished");
        if (
          formatData.reference_document_list &&
          formatData.reference_document_list.length > 0
        ) {
          const isValid = await validateSalesItemQty(
            formatData.item_list,
            SalesReferenceDocumentType.CashSales
          );
          if (!isValid) {
            enqueueSnackbar("จำนวนสินค้าเกินจำนวนจากใบสั่งขาย", {
              variant: "error",
            });
            return;
          }
        }
        const { CashSalesUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          updateInput: {
            ...formatData,
            last_updated_by: {
              user_id: authUser!.id,
              user_unique_id: authUser!.unique_id,
              first_name: authUser!.first_name,
              last_name: authUser!.last_name,
              email: authUser!.email,
              img_url: authUser!.img_url,
            },
          } as CashSalesUpdateInput,
        });
        enqueueSnackbar(`${t("button.send")}สำเร็จ`, {
          variant: "success",
        });
        await refetch();

        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.CashSales,
          reference_id: CashSalesUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "finished",
            updated_fields: {
              net_amount:
                netAmount?.toFixed(2) === CashSalesUpdate.net_amount?.toFixed(2)
                  ? undefined
                  : CashSalesUpdate.net_amount,
            },
          },
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${t("button.send")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const externalReferenceHandler = async (data: IExternalProps) => {
    try {
      await update({
        updateInput: {
          external_reference_no: data.external_reference_no,
          last_updated_by: {
            user_id: authUser!.id,
            user_unique_id: authUser!.unique_id,
            first_name: authUser!.first_name,
            last_name: authUser!.last_name,
            email: authUser!.email,
            img_url: authUser!.img_url,
          },
        },
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      });
      enqueueSnackbar(`${t("button.save")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.save")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const { CashSalesUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        updateInput: {
          item_price_list_id: data.item_price_list_id as number,
          flag_status: flag_status,
          last_updated_by: {
            user_id: authUser!.id,
            user_unique_id: authUser!.unique_id,
            first_name: authUser!.first_name,
            last_name: authUser!.last_name,
            email: authUser!.email,
            img_url: authUser!.img_url,
          },
        },
      });

      enqueueSnackbar(`${t("button.cancel")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.CashSales,
        reference_id: CashSalesUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.cancel")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating || remainingPayment < 0}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              disabled={isCreating || isUpdating || remainingPayment !== 0}
              onClick={handleSubmit(finishHandler)}
            />
          </>
        );
      case "not_approved":
      case "approved":
      case "cancelled":
        return;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || remainingPayment < 0}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("button.send")}
              variant="contained"
              onClick={handleSubmit(finishHandler)}
              disabled={isCreating || remainingPayment !== 0}
            />
          </>
        );
    }
  };

  useSalesError(errors);

  if (isOptionLoading || (id && (isLoadingData || isLoading))) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CashSalesHeader
        externalReferenceHandler={externalReferenceHandler}
        cancelHandler={cancelHandler}
      />
      <CustomerInfo documentType="cash_sales" />
      <AddressInfo />
      <SalesInfo documentType="cash_sales" />
      <SalesItemList documentType="cash_sales" />
      <SalesPromotion />
      <SalesFooter documentType="cash_sales" />
      {status !== "finished" && <SalesPayment documentType="cash_sales" />}
      {/* <CuttingDocument /> */}
      <BottomNavbar>
        {!disabled && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
