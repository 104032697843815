import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { IHeaderTable, ISelectOption } from "../../../types/global";
import { useUserOption } from "../../../hooks/use-user-option";
import ControlledSelect from "../../Controller/ControlledSelect";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect } from "react";
import { IBranchPermission, IUser } from "../../../types/Auth/user";
import { useDisable } from "../../../hooks/use-disable";
import LabelInput from "../../UI/LabelInput";
import { accessFormatter } from "../../../utils/Formatter/Global";
import { useStateContext } from "../../../contexts/auth-context";
// import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useParams } from "react-router-dom";

const headers: IHeaderTable[] = [
  {
    thaiLabel: "รายการ",
    width: 200,
  },
  {
    thaiLabel: "ดู",
    width: 200,
  },
];

const accessOption: ISelectOption[] = [
  {
    label: "เปิด",
    value: "open",
  },
  {
    label: "ปิด",
    value: "close",
  },
];

interface Props {
  setRemoveIds: Dispatch<SetStateAction<number[]>>;
  isSetting: boolean;
}

const BranchPermissionTable = ({ setRemoveIds, isSetting }: Props) => {
  const {
    state: { authUser },
  } = useStateContext();

  const { id } = useParams();
  const { branches, branchUser } = useUserOption(
    isSetting ? authUser?.id : parseInt(id || "")
  );
  const [disabled] = useDisable();
  const { control, getValues } = useFormContext<IUser>();

  const original_branch = useWatch({
    control,
    name: "original_branch",
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "branch_permission",
    keyName: "genId",
  });

  // const [page, setPage] = useState<number>(0);
  // const [filter, setFilter] = useState<string>("");
  // const [filterData, setFilterData] = useState<any[]>([]);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  useEffect(() => {
    if (branches && branches.length > 0) {
      const mappedBranchId =
        branchUser?.map((branch) => branch.branch_id) || [];
      const allBranches: IBranchPermission[] = branches.map((branch) => ({
        id: branch.id,
        unique_id: branch.unique_id,
        name: branch.name || "",
        is_hq: branch.is_hq,
        access: "close",
      }));

      const updateAccessStatus = (
        allBranches: IBranchPermission[],
        mappedBranchId: number[]
      ) => {
        return allBranches.map((branch) => {
          return {
            ...branch,
            access:
              mappedBranchId.includes(branch.id) ||
              branch.id === original_branch
                ? "open"
                : branch.access,
          };
        });
      };

      const formattedBranches: IBranchPermission[] = updateAccessStatus(
        allBranches,
        mappedBranchId as number[]
      );

      const sortByUniqueId = (a: IBranchPermission, b: IBranchPermission) => {
        // Use localeCompare for case-insensitive sorting
        return a.unique_id.localeCompare(b.unique_id);
      };

      const sortedBranches = formattedBranches.sort(sortByUniqueId);

      replace(sortedBranches);
      // setFilterData(formattedBranches);
    }
  }, [branchUser, branches, original_branch, replace]);

  // const emptyRows =
  //   page > 0
  //     ? Math.max(0, (1 + page) * 10 - filterData.length)
  //     : 10 - filterData.length;

  // const onFilterChangeHandler = (e: any) => {
  //   const value = e.target.value;
  //   if (value) {
  //     const filtered = fields.filter((field) => field.name.includes(value));
  //     setPage(0);
  //     setFilterData(filtered);
  //   } else {
  //     setFilterData(fields);
  //   }
  //   setFilter(value);
  // };

  const branch_permission =
    useWatch({
      control,
      name: "branch_permission",
    }) || [];

  const isHqOpen =
    branch_permission.filter(
      (permission) => permission.is_hq && permission.access === "open"
    ).length > 0;

  return (
    <Box sx={{ width: "100%" }}>
      {/* <CustomizedTextField
        sx={{
          width: 300,
          mb: 2,
        }}
        value={filter}
        onChange={onFilterChangeHandler}
        variant="standard"
        placeholder="ค้นหาสาขา"
      /> */}
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    color={"primary.main"}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields
              // .slice(page * 10, page * 10 + 10)
              .map((branch, index) => (
                <TableRow key={branch.genId}>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    <Typography>
                      {branch.name}{" "}
                      {branch.id === original_branch && "(ต้นสังกัด)"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                  >
                    {disabled ? (
                      <LabelInput
                        label=""
                        value={accessFormatter(
                          getValues(`branch_permission.${index}.access`)
                        )}
                      />
                    ) : (
                      <ControlledSelect
                        control={control}
                        name={`branch_permission.${index}.access`}
                        options={accessOption}
                        sx={{ width: 150, margin: "auto" }}
                        onChange={(e: any) => {
                          const currentId = getValues(
                            `branch_permission.${index}.id`
                          );
                          const isHqBranch = getValues(
                            `branch_permission.${index}.is_hq`
                          );

                          if (e.target.value === "close") {
                            setRemoveIds((prev) => [...prev, currentId]);
                          } else {
                            if (isHqBranch) {
                              const currentPermission =
                                getValues("branch_permission");

                              const newPermission =
                                currentPermission?.map((permission) => ({
                                  ...permission,
                                  access: "open",
                                })) || [];
                              replace(newPermission);
                            }
                            setRemoveIds((prev) =>
                              prev.filter((id) => id !== currentId)
                            );
                          }
                        }}
                        disabled={!branch.is_hq && isHqOpen}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {/* {emptyRows > 0 ? (
              emptyRows === 10 ? (
                <TableRow
                  style={{
                    height: (disabled ? 38 : 54.13) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6}>
                    <Typography align="center">ไม่พบสาขา</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  style={{
                    height: (disabled ? 38 : 54.13) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )
            ) : null} */}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={filterData.length}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      /> */}
    </Box>
  );
};

export default BranchPermissionTable;
