import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { IMenuOption, IRadio } from "../../../../../types/global";
import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import { useDisable } from "../../../../../hooks/use-disable";
import ControlledTextField from "../../../../Controller/ControlledTextField";
import CouponTable from "../../../../Table/Marketing/Coupon";
import CustomizedMenuOptions from "../../../../Custom/CustomizedMenuOptions";
import CouponImporterModal from "../../../../UI/Modal/CouponImporterModal";
import { useModal } from "../../../../../hooks/use-modal";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useParams } from "react-router-dom";
import { useCouponGenerateExportMutation } from "../../../../../generated/marketing";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { useSnackbar } from "notistack";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import ConfirmationModal from "../../../../UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CustomizedChips from "../../../../Custom/CustomizedChips";

const PromotionCoupon = () => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<IPromotion>();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled] = useDisable();
  const { id } = useParams();

  const watchIsApplied = useWatch({
    control,
    name: "is_applied",
  });
  const isApplied = Boolean(watchIsApplied);

  const changeToSingleUseCouponHandler = () => {
    setValue("coupon_code", "");
  };

  const cancelConfirmationHandler = () => {
    setValue("coupon_type", "many_time");
    closeConfirmationHandler();
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(changeToSingleUseCouponHandler);

  const couponOptions: IMenuOption[] = [
    {
      value: "นำเข้า",
    },
    {
      value: "นำออก",
      disabled: !Boolean(id),
    },
  ];

  const couponTypeList: IRadio[] = [
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.coupon_type.many_time")}
          </Typography>
          <CustomizedTooltip
            title={t("marketing.promotion.tooltip.coupon.many_time")}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "many_time",
    },
    {
      label: (
        <Box display={"flex"} alignItems={"center"} gap={0.5}>
          <Typography
            color={disabled || isApplied ? "rgba(0, 0, 0, 0.38)" : undefined}
          >
            {t("marketing.promotion.coupon_type.one_time")}
          </Typography>
          <CustomizedTooltip
            title={t("marketing.promotion.tooltip.coupon.one_time")}
          >
            <InfoOutlinedIcon color={"primary"} sx={{ fontSize: 16 }} />
          </CustomizedTooltip>
        </Box>
      ),
      value: "one_time",
    },
  ];

  const couponType = useWatch({ control, name: "coupon_type" });

  const couponCount = useWatch({ control, name: "coupon_count" });
  const availableCouponCount = useWatch({
    control,
    name: "coupon_available_count",
  });

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const graphQLClient = createGraphQLClientWithMiddleware("marketing");

  const { mutateAsync: exporter, isLoading: isExporting } =
    useCouponGenerateExportMutation<Error>(graphQLClient);

  const exportHander = async () => {
    try {
      const { CouponGenerateExport } = await exporter({
        exportInput: {
          promotion_id: id ? parseInt(id) : 0,
        },
      });
      enqueueSnackbar(`${t("button.export")}สำเร็จ`, {
        variant: "success",
      });
      window.open(CouponGenerateExport || "", "_blank");
    } catch (err) {
      enqueueSnackbar(`${t("button.export")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CustomizedBox>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display="flex" gap={0.3}>
            <Typography
              fontWeight={600}
              color={
                Boolean(errors.coupon_code || errors.state_uuid)
                  ? "error.main"
                  : undefined
              }
            >
              {t("marketing.promotion.coupon.index")}
            </Typography>
            <Typography fontWeight={600} color={"error.main"}>
              *
            </Typography>
            {couponType === "one_time" && Boolean(couponCount) && (
              <CustomizedChips
                value={`${t(
                  "marketing.promotion.remaining_coupon"
                )} ${couponCount} จาก ${availableCouponCount}`}
                variant="filled"
                sx={(theme) => ({
                  marginLeft: "0.5rem",
                  backgroundColor: "#F0F1FA",
                  color: theme.palette.primary.main,
                })}
              />
            )}
          </Box>
          {couponType === "one_time" && !isApplied && (
            <CustomizedMenuOptions
              isIcon
              options={couponOptions}
              size="small"
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `นำเข้า`:
                    openModalHandler();
                    break;
                  case `นำออก`:
                    exportHander();
                    break;
                  default:
                }
              }}
            />
          )}
        </Box>
        <ControlledRadioGroup
          control={control}
          name="coupon_type"
          radioLists={couponTypeList}
          row
          disabled={disabled || isApplied}
          onChange={(_, value) => {
            if (value === "one_time" && getValues("coupon_code")) {
              openConfirmationHandler();
            }
          }}
        />
        <Box mt={1}>
          {couponType && couponType === "many_time" && (
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledTextField
                  label={t("marketing.promotion.coupon.code")}
                  control={control}
                  name="coupon_code"
                  error={Boolean(errors.coupon_code)}
                  helperText={errors.coupon_code?.message}
                  viewMode={disabled}
                />
              </Grid>
            </Grid>
          )}
          {couponType && couponType === "one_time" && (
            <CouponTable gridRef={gridRef} />
          )}
        </Box>
      </CustomizedBox>
      <CouponImporterModal
        open={modal}
        closeModalHandler={closeModalHandler}
        gridRef={gridRef}
      />
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 9999 })}
        open={isExporting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmationModal
        title="การเปลี่ยนตัวเลือก"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนตัวเลือก"}</Typography>
            <Typography>{"ข้อมูลด้านล่างจะถูกล้างค่า"}</Typography>
          </Box>
        }
        open={confirmation}
        handleClose={cancelConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default PromotionCoupon;
