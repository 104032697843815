import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useModal } from "../../../../hooks/use-modal";
import { useDisable } from "../../../../hooks/use-disable";
import { useConfirmation } from "../../../../hooks/use-confirmation";
import { useDeliveryOrderOption } from "../../../../hooks/Logistic/DeliveryOrder/use-delivery-order-option";
import { usePurchaseOption } from "../../../../hooks/Purchase/use-purchase-option";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import LabelInput from "../../../UI/LabelInput";
import ApproverBox from "../../../UI/ApproverBox";
import EmployeeListForm from "../../EmployeeList";
import Confirmation from "../../../UI/Confirmation";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";

import { IExternalProps } from "../../../../types/Sales";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { IApprovalTemplate, IMenuOption } from "../../../../types/global";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  PurchaseModelType,
  PurchaseUniqueIdGenerateQuery,
  usePurchaseUniqueIdGenerateQuery,
} from "../../../../generated/purchase";

import ExternalReferenceModal from "../../../UI/Modal/ExternalReferenceModal";

import {
  copyPurchaseOrderFormatter,
  createGoodsReceiveFromPurchaseOrder,
  createPurchaseRuturnFromPurchaseOrder,
} from "../../../../utils/Formatter/Purchase/PurchaseOrder";
import { branchInfoFromSessionStorage } from "../../../../utils/Global";
import { useStateContext } from "../../../../contexts/auth-context";
import CalcelReceiveQtyModal from "../../../UI/Modal/CalcelReceiveQtyModal";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import {
  LogisticModelType,
  LogisticUniqueIdGenerateQuery,
  useLogisticUniqueIdGenerateQuery,
} from "../../../../generated/logistic";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => Promise<void>;
  disabledField?: boolean;
};

const DeliveryOrderHeader = ({
  cancelHandler,
  editClickHandler,
  disabledField,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled] = useDisable();
  const { branch_id, is_hq } = branchInfoFromSessionStorage();
  const {
    state: { authUser },
  } = useStateContext();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<any>();

  const {
    branchesOptions,
    warehouseOptions,
    renderBranchOptions,
    renderWarehousesOptions,
  } = usePurchaseOption();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_document_list = useWatch({
    control,
    name: "reference_document_list",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("purchase.order.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("logistic");

  const { refetch: refetchUniqueId } =
    useLogisticUniqueIdGenerateQuery<LogisticUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: LogisticModelType.DeliveryOrder,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
    setValue("branch_id", parseInt(branch_id || "0"));
  }, [branch_id, refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useDeliveryOrderOption(status);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  // const notEditStatus = ["issued"].includes(status || "") || disabled;

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {t("logistic.delivery_order.index")}
            </Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid container spacing={1.5} justifyContent="end">
              {status !== "not_approve" && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      if (
                        value.innerText === `พิมพ์${t("purchase.order.index")}`
                      ) {
                        navigate(`/purchase/order/${id}/pdf`);
                      }
                    }}
                    disabled={
                      !id || status === "cancelled" || status === "not_approved"
                    }
                  />
                </Grid>
              )}
              {["draft", "issued"].includes(status || "") && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ยกเลิก":
                          openCancelConfirmation();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {["delivering", "delivery_success", "delivery_fail"].includes(
                status || ""
              ) && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedButton
                    variant="contained"
                    title="บันทึกการจัดส่ง"
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("purchase.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7} mx={disabled ? 1.5 : 0}>
              <Box display="flex" gap={1} flexWrap="wrap">
                {reference_document_list && reference_document_list.length > 0
                  ? reference_document_list.map((reference: any) => (
                      <CustomizedChips
                        key={`${reference.document_id}-${reference.document_unique_id}-${reference.document_type}`}
                        onClick={() => {
                          window.open(
                            `/sales/${
                              reference.document_type === "sales_order"
                                ? "order"
                                : "cash-sales"
                            }/${encodeURIComponent(reference.document_id)}`,
                            "_blank"
                          );
                        }}
                        value={reference.document_unique_id}
                        color="primary"
                      />
                    ))
                  : "-"}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.reference_no")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="reference_no"
                control={control}
                placeholder={t("purchase.reference_no")}
                error={Boolean(errors?.reference_no)}
                helperText={
                  errors?.reference_no &&
                  errors?.reference_no.message?.toString()
                }
                disabled={disabled}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>{t("branch.index")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              {disabled ? (
                <LabelInput
                  label={""}
                  value={
                    getValues("branch")?.unique_id +
                      " - " +
                      getValues("branch")?.name || ""
                  }
                />
              ) : (
                <Controller
                  control={control}
                  name="branch_id"
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      placeholder={t("branch.index")}
                      options={branchesOptions}
                      disabled={is_hq !== "true" || disabled || disabledField}
                      onChange={(_, newValue) => {
                        field.onChange(newValue?.value ?? "");
                      }}
                      value={renderBranchOptions(field.value)}
                      error={Boolean(errors.branch_id)}
                      helperText={errors?.branch_id?.message?.toString()}
                      required
                    />
                  )}
                />
                // <ControlledSelect
                //   control={control}
                //   name="branch_id"
                //   placeholder={t("branch.index")}
                //   options={branchesOptions}
                //   disabled={is_hq !== "true" || disabled}
                //   error={Boolean(errors.branch_id)}
                //   helperText={errors?.branch_id?.message?.toString()}
                // />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("inventory.list.destinationWarehouse")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              {disabled ? (
                <LabelInput
                  label={""}
                  value={
                    getValues("destination_warehouse_id")
                      ? getValues("destination_warehouse")?.unique_id +
                          " - " +
                          getValues("destination_warehouse")?.name || ""
                      : "-"
                  }
                />
              ) : (
                <Controller
                  control={control}
                  name="destination_warehouse_id"
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      placeholder={t("inventory.list.destinationWarehouse")}
                      disabled={disabledField}
                      options={warehouseOptions}
                      onChange={(_, newValue) => {
                        field.onChange(newValue?.value ?? "");
                      }}
                      value={renderWarehousesOptions(field.value)}
                      error={Boolean(errors.destination_warehouse_id)}
                      helperText={errors?.destination_warehouse_id?.message?.toString()}
                      required
                    />
                  )}
                />
                // <ControlledSelect
                //   control={control}
                //   name="destination_warehouse_id"

                // error={Boolean(errors.destination_warehouse_id)}
                // helperText={errors?.destination_warehouse_id?.message?.toString()}
                // />
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} sm={12} md={4}>
              <Typography fontWeight={600}>
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box mx={disabled ? 1.5 : 0}>
                <EmployeeListForm />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                label={t("date.delivery_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="exported_date"
                control={control}
                label={t("date.issued_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={cancelConfirmation}
        handleClose={closeCancelConfirmation}
        action={submitCancelConfirmation}
      />
    </Box>
  );
};

export default DeliveryOrderHeader;
