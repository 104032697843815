import dayjs from "dayjs";
import {
  BranchUserGetByUserIdQuery,
  BranchesFindManyAggridQuery,
  UserCreateInput,
  UserUpdateInput,
} from "../../generated/general";
import { IBranchPermission, IUser } from "../../types/Auth/user";
import { uploadFileToS3 } from "../s3";

export const userCreateFormatter = async (
  data: IUser,
  id: string | undefined
) => {
  const {
    created_date,
    last_updated_date,
    confirm_password,
    password,
    status,
    branch_permission,
    original_branch,
    original_branch_object,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const formatData: UserCreateInput = {
    ...otherData,
    original_branch: original_branch ? parseInt(original_branch.toString()) : 1,
    img_url,
    user_password: confirm_password || "",
    status: status ? 1 : 0,
  };

  return formatData;
};

export const userUpdateFormatter = async (data: IUser) => {
  const {
    id,
    created_date,
    last_updated_date,
    last_login_branch,
    confirm_password,
    password,
    status,
    branch_permission,
    original_branch,
    original_branch_object,
    ...otherData
  } = data;

  let img_url: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "user",
        id?.toString() || ""
      );
      img_url.push(Location);
    } else {
      img_url = data.img_url;
    }
  }

  const formatData: UserUpdateInput = {
    ...otherData,
    original_branch: original_branch ? parseInt(original_branch.toString()) : 1,
    img_url,
    status: status ? 1 : 0,
  };

  return formatData;
};

export const userQueryFormatter = (
  data: IUser,
  branches?: BranchesFindManyAggridQuery["BranchesFindManyAggrid"]["results"],
  branchUser?: BranchUserGetByUserIdQuery["BranchUserGetByUserId"]
): IUser => {
  const { start_date, inactive_date, ...otherData } = data;

  let formattedBranches: IBranchPermission[] = [];

  if (branches && branches.length > 0) {
    const mappedBranchId = branchUser?.map((branch) => branch.branch_id) || [];
    const allBranches: IBranchPermission[] = branches.map((branch) => ({
      id: branch.id,
      unique_id: branch.unique_id,
      name: branch.name || "",
      is_hq: branch.is_hq,
      access: "close",
    }));

    const updateAccessStatus = (
      allBranches: IBranchPermission[],
      mappedBranchId: number[]
    ) => {
      return allBranches.map((branch) => ({
        ...branch,
        access: mappedBranchId.includes(branch.id) ? "open" : branch.access,
      }));
    };

    formattedBranches = updateAccessStatus(
      allBranches,
      mappedBranchId as number[]
    );
  }

  const sortByUniqueId = (a: IBranchPermission, b: IBranchPermission) => {
    // Use localeCompare for case-insensitive sorting
    return a.unique_id.localeCompare(b.unique_id);
  };

  const sortedBranches = formattedBranches.sort(sortByUniqueId);

  const formatData = {
    ...otherData,
    start_date: start_date ? dayjs(start_date) : null,
    inactive_date: inactive_date ? dayjs(inactive_date) : null,
    branch_permission: sortedBranches,
  };
  return formatData;
};

export const formatAuthToUserPayload = (authUser: IUser | null) => {
  if (authUser)
    return {
      user_unique_id: authUser?.unique_id,
      email: authUser?.email,
      first_name: authUser?.first_name,
      last_name: authUser?.last_name,
      img_url: authUser?.img_url,
    };
  else return undefined;
};
