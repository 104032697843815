import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import DeliveryOrderTable from "../../../components/Table/Logistic/DeliveryOrder";
// import ExporterPurchaseModal from "../../../components/U/Modal/ExporterPurchaseModal";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";

import { useModal } from "../../../hooks/use-modal";
import { PurchaseExportType } from "../../../generated/purchase";
import { branchInfoFromSessionStorage } from "../../../utils/Global";
import { IBreadcrumbsAndMenu, IMenuOption, ITab } from "../../../types/global";

const DeliveryOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { is_hq } = branchInfoFromSessionStorage();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.delivery_order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.issued"),
      path: `${pathname}?filter=issued`,
    },
    {
      label: t("status.delivering"),
      path: `${pathname}?filter=delivering`,
    },
    {
      label: t("status.delivery_success"),
      path: `${pathname}?filter=delivery_success`,
    },
    {
      label: t("status.delivery_fail"),
      path: `${pathname}?filter=delivery_fail`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  // const onFilterReset = () => {
  //   if (gridRef) {
  //     gridRef.current?.api.setFilterModel({});
  //   }
  // };

  const options: IMenuOption[] = [
    {
      value: t("logistic.export"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("logistic.delivery_order.index")}
        </Typography>
        <Box display="flex" gap={1}>
          {is_hq === "true" && (
            <CustomizedMenuOptions
              isIcon
              options={options}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case `${t("logistic.export")}`:
                    openModalHandler();
                    break;
                  default:
                }
              }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      </Box>
      <DeliveryOrderTable isFilter={isFilter} />
      {/* <ExporterPurchaseModal
        open={modal}
        closeModalHandler={closeModalHandler}
        documentType={PurchaseExportType.PurchaseReturn}
      /> */}
    </>
  );
};

export default DeliveryOrder;
