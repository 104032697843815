import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ISalesItemList } from "../../../types/Sales";
import { ITEM_SKU_DETAIL_AGGRID } from "../../../services/AgGrid/InventoryAggrid";
import {
  CashSalesFindUniqueQuery,
  SalesReferenceDocumentType,
} from "../../../generated/sales";
import dayjs from "dayjs";
import { ICashSales } from "../../../types/Sales/cashSales";
import { v4 as uuidv4 } from "uuid";
import { IUser } from "../../../types/Auth/user";

export const cashSalesCreatePayloadFormatter = (
  data: ICashSales,
  status: string
) => {
  const {
    item_list,
    payment_list,
    deduction_document_list,
    customer_details,
    tag_list,
    created_date,
    item_price_list,
    sales_channel,
    is_have_payment,
    is_have_deduct,
    promotion_list,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({ id, payment_channel_unique_id_name, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formatDeductionList = deduction_document_list.map(
    ({ id, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formattedPromotionList = promotion_list?.map((promotion) => {
    return {
      ...promotion,
      id: promotion.promotion_log_id,
      promotion_log_id: undefined,
    };
  });

  const { unique_id_name, ...customer } = customer_details;

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    item_list: formatItemList,
    payment_list: formatPaymentList,
    deduction_document_list: formatDeductionList,
    main_status: status,
    sub_status: status,
    tag_id_list: formatTagList,
    promotion_list: formattedPromotionList,
    coupon_code_list: promotion_list?.map((promotion) => promotion.coupon_code),
  };
  return formatPayload;
};

export const cashSalesUpdatePayloadFormatter = (
  data: ICashSales,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    main_status,
    flag_status,
    aggrid_status,
    customer_details,
    last_updated_date,
    item_list,
    payment_list,
    deduction_document_list,
    tag_list,
    created_by,
    created_date,
    branch,
    sales_channel,
    is_have_payment,
    is_have_deduct,
    item_price_list,
    promotion_list,
    ...otherData
  } = data;
  const { unique_id_name, ...customer } = customer_details;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formatPaymentList = payment_list.map(
    ({ payment_channel_unique_id_name, ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formatDeductionList = deduction_document_list.map(
    ({ ...otherItem }) => ({
      ...otherItem,
      branch_id: otherData.branch_id,
      reference_document_type: SalesReferenceDocumentType.CashSales,
      reference_unique_id: data.unique_id,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.id) : [];

  const formattedPromotionList = promotion_list?.map((promotion) => {
    return {
      ...promotion,
      id: promotion.promotion_log_id,
      promotion_log_id: undefined,
    };
  });

  const formatPayload = {
    ...otherData,
    customer_details: customer,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    payment_list: formatPaymentList,
    deduction_document_list: formatDeductionList,
    tag_id_list: formatTagList,
    main_status: status,
    sub_status: status,
    promotion_list: formattedPromotionList,
  };
  return formatPayload;
};

export const cashSalesQueryFormatter = async (
  data: CashSalesFindUniqueQuery["CashSalesFindUnique"]
) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("wms");
  const allItemListUniqueId =
    data.item_list &&
    data.item_list.length > 0 &&
    data.item_list.map((item) => item?.item_sku_name);

  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEM_SKU_DETAIL_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });

  const { results: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: ISalesItemList[] = [];

  if (data.item_list && data.item_list.length > 0) {
    data.item_list.forEach((item) => {
      const foundItemIndex = itemSkuDetails.findIndex(
        (realItem: any) => realItem.sku_name === item?.item_sku_name
      );

      const itemType = item as ISalesItemList;

      formatItemList.push({
        ...itemType,
        uom_group: itemSkuDetails[foundItemIndex]?.item.uom_group,
        item_sku_qty: itemSkuDetails[foundItemIndex]?.total_sku_qty,
      });
    });
  }

  const formatCustomer = {
    ...data.customer_details,
    unique_id_name: `${data.customer_unique_id} - ${data.customer_details.name}`,
  };
  const isHaveDeduct = Boolean(
    data.deduction_document_list && data.deduction_document_list.length > 0
  );

  const isHavePayment = Boolean(
    data.payment_list && data.payment_list.length > 0
  );

  const formatPaymentChannel = data.payment_list?.map(
    ({ payment_date, cheque_date, ...other }) => ({
      ...other,
      payment_channel_unique_id_name: `${other.payment_channel_unique_id} - ${other.payment_channel_name}`,
      payment_date: payment_date ? dayjs(payment_date) : undefined,
      cheque_date: cheque_date ? dayjs(cheque_date) : undefined,
    })
  );

  const formatDeduction = data.deduction_document_list?.map(
    ({ deduction_issue_date, ...other }) => ({
      ...other,
      deduction_issue_date: deduction_issue_date
        ? dayjs(deduction_issue_date)
        : undefined,
    })
  );

  const formatPromotionList = data.promotion_list?.map((promotion) => {
    return {
      ...promotion,
      promotion_log_id: promotion.id,
      discount_rate: parseInt(promotion.discount_rate),
      total_discount_amount: parseInt(promotion.total_discount_amount),
    };
  });

  const formatPayload = {
    ...data,
    item_price_list_id: data.item_price_list_id || 0,
    customer_details: formatCustomer,
    item_list: formatItemList,
    created_date: data.created_date ? dayjs(data.created_date) : undefined,
    issue_date: data.issue_date ? dayjs(data.issue_date) : undefined,
    due_date: data.due_date ? dayjs(data.due_date) : undefined,
    is_have_deduct: isHaveDeduct,
    is_have_payment: isHavePayment,
    payment_list: formatPaymentChannel,
    deduction_document_list: formatDeduction,
    promotion_list: formatPromotionList,
  } as ICashSales;

  return formatPayload;
};

export const copyCashSalesFormatter = (
  data: ICashSales,
  currentUser: IUser | null
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      created_by,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      branch,
      is_have_deduct,
      is_have_payment,
      deduction_document_list,
      payment_list,
      paid_total_amount,
      paid_net_amount,
      sales_channel,
      reference_document_list,
      is_original_print,
      tax_invoice_no,
      // related_user_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list && item_list.length > 0
        ? item_list.map(
            ({
              id,
              unique_id,
              reference_unique_id,
              qty_invoiced,
              qty_issued,
              qty_ordered,
              qty_returned,
              qty_shipped,
              qty_to_ship,
              qty_received,
              reference_line_item,
              ...otherItemList
            }) => ({
              ...otherItemList,
              unique_id: uuidv4(),
            })
          )
        : [];

    // const formatRelatedUserList =
    //   created_by?.user_unique_id !== currentUser?.unique_id
    //     ? [created_by, ...related_user_list]
    //     : related_user_list;

    return {
      ...otherData,
      item_list: formatItemList,
      copied_id: id,
      copied_unique_id: unique_id,
      is_have_deduct: false,
      is_have_payment: false,
      payment_list: [],
      deduction_document_list: [],
      paid_total_amount: 0,
      paid_net_amount: 0,
      // related_user_list: formatRelatedUserList,
    };
  }
};

export const createSalesReturnFromCashSales = (data: ICashSales) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      delivery_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      last_updated_date,
      reference_no,
      branch,
      sales_channel,
      tag_list,
      is_have_deduct,
      is_have_payment,
      deduction_document_list,
      payment_list,
      need_tax_invoice,
      tax_invoice_no,
      paid_net_amount,
      paid_total_amount,
      payment_total_amount,
      deduction_total_amount,
      is_original_print,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          id,
          unique_id,
          reference_document_type,
          reference_unique_id,
          qty,
          qty_invoiced,
          qty_issued,
          qty_ordered,
          qty_returned,
          qty_shipped,
          qty_to_ship,
          qty_received,
          ...otherItemList
        }) => {
          //CASE: Default value equal 1
          // const { uom_group, uom } = otherItemList;
          // const currentUomId = parseInt(uom || "");
          // let stock_qty: number = 1;
          // if (uom_group) {
          //   if (currentUomId !== uom_group.base_uom?.id) {
          //     if (
          //       uom_group.uom_conversion_list &&
          //       uom_group.uom_conversion_list.length > 0
          //     ) {
          //       const conversionUom = uom_group.uom_conversion_list.find(
          //         (conversion) => conversion.target_uom_id === currentUomId
          //       );
          //       if (conversionUom) {
          //         const targetUomQty =
          //           conversionUom.base_uom_rate / conversionUom.target_uom_rate;
          //         stock_qty = targetUomQty;
          //       }
          //     }
          //   }
          // }

          return {
            ...otherItemList,
            unique_id: uuidv4(),
            so_qty: qty,
            qty: qty - (qty_returned || 0),
            reference_line_item: {
              line_item_unique_id: unique_id,
              line_item_document_type: SalesReferenceDocumentType.CashSales,
            },
          };
        }
      );

    return {
      ...otherData,
      is_effect_stock: true,
      reference_document_list: [
        {
          document_id: data.id,
          document_unique_id: data.unique_id,
          document_type: SalesReferenceDocumentType.CashSales,
        },
      ],
      item_list: formatItemList,
    };
  }
};
