import { GraphQLClient } from "graphql-request";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import CheckboxAggridModal from "./CheckboxAggridModal";
import {
  SalesChannelsFindAllQuery,
  useSalesChannelsFindAllQuery,
} from "../../../generated/general";

type CheckedSalesChannelModalProps = {
  showSelectSalesChannel: boolean;
  closeSalesChannelTable: () => void;
  finishSalesChannelSelect: (data: any) => void;
  salesChannelIds: string[];
  setSalesChannelIds: Dispatch<SetStateAction<string[]>>;
  salesChannelIdsSnapshot: string[];
  setSalesChannelIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowSelection?: "single" | "multiple";
  keyName?: string;
};

const CheckedSalesChannelModal = ({
  showSelectSalesChannel,
  closeSalesChannelTable,
  finishSalesChannelSelect,
  salesChannelIds,
  setSalesChannelIds,
  salesChannelIdsSnapshot,
  setSalesChannelIdsSnapshot,
  rowSelection,
  keyName,
}: CheckedSalesChannelModalProps) => {
  const { t } = useTranslation();
  const selectSalesChannelGridRef = useRef();

  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("setting.unique_id"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name_th",
      headerName: t("setting.name_th"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name_en",
      headerName: t("setting.name_en"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: t("setting.description"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData } =
    useSalesChannelsFindAllQuery<SalesChannelsFindAllQuery>(graphQLClient);

  const getRowId = useCallback(
    (params: GetRowIdParams) => {
      return keyName ? params.data[keyName] : params.data.unique_id;
    },
    [keyName]
  );

  return (
    <CheckboxAggridModal
      modalTitle={t("setting.sales.channel")}
      btnTitle={t("button.choose")}
      gridRef={selectSalesChannelGridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishSalesChannelSelect}
      modalIsOpen={showSelectSalesChannel}
      getRowId={getRowId}
      closeModal={closeSalesChannelTable}
      selectedIds={salesChannelIds}
      setSelectedIds={setSalesChannelIds}
      idsSnapshot={salesChannelIdsSnapshot}
      setIdsSnapshot={setSalesChannelIdsSnapshot}
      keyName={keyName}
      rowData={rowData?.SalesChannelsFindAll || []}
    />
  );
};

export default CheckedSalesChannelModal;
