import { PriceVatType } from "../../generated/sales";

export const statusValueFormatter = (status: string) => {
  switch (status) {
    case "draft":
      return "ร่าง";
    case "in_transit":
      return "ระหว่างทาง";
    case "wait_transfer":
      return "รอโอนย้าย";
    case "wait_receive":
      return "รอนำเข้า";
    case "wait_issue":
      return "รอนำออก";
    case "issued":
      return "นำออกแล้ว";
    case "delivering":
      return "กำลังจัดส่ง";
    case "delivery_success":
      return "จัดส่งสำเร็จ";
    case "delivery_fail":
      return "จัดส่งไม่สำเร็จ";
    case "wait_approve":
      return "รออนุมัติ";
    case "not_approved":
      return "ไม่อนุมัติ";
    case "approved":
      return "อนุมัติแล้ว";
    case "wait_accept":
      return "รอตอบรับ";
    case "accepted":
      return "ตอบรับแล้ว";
    case "wait_payment":
      return "รอชำระ";
    case "partially_payment":
      return "ชำระแล้วบางส่วน";
    case "fully_payment":
      return "ชำระแล้ว";
    case "wait_deduction":
      return "รอตัดชำระ";
    case "partially_deduction":
      return "ตัดชำระแล้วบางส่วน";
    case "wait_deliver":
      return "รอจัดส่ง";
    case "expired":
      return "เกินเวลา";
    case "partially_ordered":
      return "สั่งซื้อแล้วบางส่วน";
    case "fully_ordered":
      return "สั่งซื้อแล้ว";
    case "partially_imported":
      return "นำเข้าแล้วบางส่วน";
    case "fully_imported":
      return "นำเข้าแล้ว";
    case "completed":
      return "สำเร็จ";
    case "not_completed":
      return "ไม่สำเร็จ";
    case "pending_manu":
      return "รอผลิต";
    case "in_progress":
      return "กำลังผลิต";
    case "finished":
      return "เสร็จสิ้น";
    case "cancelled":
      return "ยกเลิก";
    default:
      return "";
  }
};

export const genderFormatter = (gender: string, isThai: boolean = true) => {
  const formatToThai = (gender: string) => {
    switch (gender) {
      case "male":
        return "ชาย";
      case "female":
        return "หญิง";
      default:
        return "อื่นๆ";
    }
  };

  const migrateToEnglish = (gender: string) => {
    switch (gender) {
      case "ชาย":
        return "male";
      case "หญิง":
        return "female";
      default:
        return "other";
    }
  };

  return isThai ? formatToThai(gender) : migrateToEnglish(gender);
};

export const contactChannelFormatter = (
  channel: string,
  isThai: boolean = true
) => {
  const formatToThai = (channel: string) => {
    switch (channel) {
      case "phone":
        return "เบอร์โทรศัพท์";
      case "email":
        return "Email";
      case "fax":
        return "แฟกซ์";
      case "line":
        return "Line";
      case "facebook":
        return "Facebook";
      case "website":
        return "Website";
      case "instagram":
        return "Instagram";
      case "mobile_phone":
        return "มือถือ";
      case "other":
        return "อื่นๆ";
      default:
        return "-";
    }
  };

  const migrateToEnglish = (channel: string) => {
    switch (channel) {
      case "เบอร์โทรศัพท์":
        return "phone";
      case "Email":
        return "email";
      case "แฟกซ์":
        return "fax";
      case "Line":
        return "line";
      case "Facebook":
        return "facebook";
      case "Website":
        return "website";
      case "Instagram":
        return "instagram";
      case "มือถือ":
        return "mobile_phone";
      case "อื่นๆ":
        return "other";
      default:
        return undefined;
    }
  };

  return isThai ? formatToThai(channel) : migrateToEnglish(channel);
};

export const companyTypeFormatter = (type: string, isThai: boolean = true) => {
  const formatToThai = (type: string) => {
    switch (type) {
      case "company":
        return "บริษัท";
      case "public_limited":
        return "บริษัทมหาชนจำกัด";
      case "limited_partnership":
        return "ห้างหุ้นส่วนจำกัด";
      case "registered_ordinary_partnership":
        return "ห้างหุ้นส่วนสามัญนิติบุคคล";
      case "association":
        return "สมาคม";
      case "other":
        return "อื่นๆ";
      case "individual":
        return "บุคคลธรรมดา";
      case "ordinary_partnership":
        return "ห้างหุ้นส่วนสามัญ";
      case "store":
        return "ร้านค้า";
      case "entities":
        return "คณะบุคคล";
      default:
        return "-";
    }
  };

  const migrateToEnglish = (type: string) => {
    switch (type) {
      case "บริษัท":
        return "company";
      case "บริษัทมหาชนจำกัด":
        return "public_limited";
      case "ห้างหุ้นส่วนจำกัด":
        return "limited_partnership";
      case "ห้างหุ้นส่วนสามัญนิติบุคคล":
        return "registered_ordinary_partnership";
      case "สมาคม":
        return "association";
      case "อื่นๆ":
        return "other";
      case "บุคคลธรรมดา":
        return "individual";
      case "ห้างหุ้นส่วนสามัญ":
        return "ordinary_partnership";
      case "ร้านค้า":
        return "store";
      case "คณะบุคคล":
        return "entities";
      default:
        return undefined;
    }
  };

  return isThai ? formatToThai(type) : migrateToEnglish(type);
};

export const businessTypeFormatter = (type: string, isThai: boolean = true) => {
  const formatToThai = (type: string) => {
    switch (type) {
      case "corporate":
        return "นิติบุคคล";
      case "individual":
        return "บุคคลธรรมดา";
      default:
        return "-";
    }
  };

  const migrateToEnglish = (type: string) => {
    switch (type) {
      case "นิติบุคคล":
        return "corporate";
      case "บุคคลธรรมดา":
        return "individual";
      default:
        return undefined;
    }
  };

  return isThai ? formatToThai(type) : migrateToEnglish(type);
};

export const countryFormatter = (country?: string, isThai: boolean = true) => {
  const formatToThai = (country?: string) => {
    switch (country) {
      case "thailand":
        return "ไทย";
      default:
        return country;
    }
  };

  const migrateToEnglish = (country?: string) => {
    switch (country) {
      case "ไทย":
        return "thailand";
      case "ประเทศไทย":
        return "thailand";
      case "ประเทศอื่นๆ":
        return "other";
      default:
        return undefined;
    }
  };

  return isThai ? formatToThai(country) : migrateToEnglish(country);
};

export const dataTypeFormatter = (type?: string) => {
  switch (type) {
    case "number":
      return "ตัวเลข";
    case "text":
      return "ตัวอักษร";
    default:
      return "-";
  }
};

export const accessFormatter = (access: string) => {
  switch (access) {
    case "open":
      return "เปิด";
    case "close":
      return "ปิด";
  }
};

export const numberFormatter = (number: number = 0) => {
  return (Math.round((number + Number.EPSILON) * 100) / 100)?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
};

export const priceNumberFormatter = (
  number: number = 0,
  fractionDigits?: number
) => {
  return (
    Math.round((number + Number.EPSILON) * 10000) / 10000
  )?.toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits ? fractionDigits : 4,
    maximumFractionDigits: fractionDigits ? fractionDigits : 4,
  });
};

export const priceVatTypeFormatter = (type: PriceVatType) => {
  if (type === PriceVatType.IncludeVat) {
    return "ราคารวมภาษี";
  } else {
    return "ราคาแยกภาษี";
  }
};

export const vatTypeFormatter = (vat: string = "") => {
  switch (vat) {
    case "0":
      return "0 %";
    case "7":
      return "7 %";
    default:
      return "ไม่มี";
  }
};

export const renderAddressString = (addressValues: any) => {
  if (addressValues) {
    const {
      address_name,
      sub_district,
      district,
      province,
      postal_code,
      country,
    } = addressValues;

    const orderedValues = {
      address_name,
      sub_district,
      district,
      province,
      postal_code,
      country,
    };
    let result = "";

    if (addressValues && orderedValues) {
      if (Array.isArray(addressValues)) {
        addressValues.forEach((item) => {
          if (item?.length > 0) {
            result = result + item + ", ";
          }
        });
      } else {
        const keys = Object.keys(orderedValues);
        keys.forEach((key) => {
          let newValue = "";
          if (key === "country") {
            newValue =
              countryFormatter(
                orderedValues[key as keyof typeof orderedValues]
              ) || "";
          } else {
            newValue = orderedValues[key as keyof typeof orderedValues];
          }

          if (newValue && newValue.length > 0) {
            result = result + newValue + ", ";
          }
        });
      }
      if (result?.trim().length === 0) {
        return "-";
      }
    }
    // remove whitespace and last comma
    return result?.trim().slice(0, -1);
    // remove whitespace and last comma
  } else return "-";
};
