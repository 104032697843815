import {
  Box,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  SxProps,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IAttachment, IDefaultForm } from "../../types/global";
import CheckIcon from "@mui/icons-material/Check";
import ControlledTextField from "../Controller/ControlledTextField";
import { formatDateTime } from "../../utils/Date";

type Props = {
  file: File & IAttachment;
  index?: number;
  removeFile: (file: any) => void;
  control: IDefaultForm["control"];
  disabled?: IDefaultForm["disabled"];
  name?: string;
  preventNameChange?: boolean;
  sx?: SxProps;
};

const AttachmentCard = ({
  file,
  index,
  removeFile,
  control,
  disabled,
  name,
  preventNameChange,
  sx,
}: Props) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const startEditingHandler = () => {
    closeActionsHandler();
    setIsEditing(true);
  };

  const finishEditingHandler = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={() => removeFile(file)}>
          {t("contact.utility.delete")}
        </MenuItem>
        {!preventNameChange && (
          <MenuItem onClick={startEditingHandler}>
            {t("contact.utility.name_change")}
          </MenuItem>
        )}
      </Menu>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "14px",
          backgroundColor: "primary.light",
          ...sx,
        }}
      >
        <CardContent
          sx={{
            "&:last-child": {
              p: 1.5,
            },
            p: 1.5,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open(file?.url, "_blank", "noopener,noreferrer")
              }
            >
              {isEditing ? (
                <ControlledTextField
                  control={control}
                  name={`${name}.${index}.attachment_name`}
                />
              ) : (
                <Typography color="primary.main" fontWeight={600} my={1}>
                  {file?.attachment_name}
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.grey[600],
                  lineHeight: 2,
                }}
              >
                {file?.uploaded_by?.first_name} {file?.uploaded_by?.last_name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.grey[600] }}
              >
                วันที่ {formatDateTime(file?.uploaded_date)}
              </Typography>
            </Box>

            {isEditing && (
              <IconButton onClick={finishEditingHandler} color="primary">
                <CheckIcon />
              </IconButton>
            )}
            {!disabled && !isEditing && (
              <IconButton onClick={openActionsHandler} color="primary">
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AttachmentCard;
