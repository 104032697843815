import { IAttachment, ICreatedBy } from "../../../types/global";
import { uploadFileToS3 } from "../../s3";

export const couponImportFormatter = async (
  files: IAttachment[],
  created_by: ICreatedBy,
  state_uuid: string,
  promotion_id?: number
) => {
  let uploadedAttachment: IAttachment[] = [];
  if (files && files.length > 0) {
    for (const file of files) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "coupon",
          created_by.user_unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        uploadedAttachment.push(formatAttachment);
      } else {
        uploadedAttachment.push(file);
      }
    }
  }

  return {
    created_by,
    promotion_id,
    state_uuid,
    url: uploadedAttachment[0].url,
  };
};
