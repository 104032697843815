import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { Box, Typography } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";
import { IRadio } from "../../../../../types/global";
import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import PromotionSpecificGroup from "./SpecificGroup";
import PromotionSpecificCustomer from "./SpecificCustomer";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import ConfirmationModal from "../../../../UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const PromotionCustomer = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IPromotion>();

  const [disabled] = useDisable();

  const watchIsApplied = watch("is_applied");
  const isApplied = Boolean(watchIsApplied);

  const cancelConfirmationHandler = () => {
    setValue(
      "customer_eligibility",
      getValues("customer_eligibility_snapshot") || "all"
    );
    closeConfirmationHandler();
  };

  const confirmEligibilityChangeHandler = () => {
    const newEligibility = getValues("customer_eligibility");
    switch (newEligibility) {
      case "all": {
        setValue("group", "");
        setValue("customer_group_name", "");
        setValue("customer_specific", []);
        break;
      }
      case "specific_groups": {
        setValue("customer_specific", []);
        break;
      }
      default:
        setValue("group", "");
        setValue("customer_group_name", "");
    }
    setValue("customer_eligibility_snapshot", newEligibility);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(confirmEligibilityChangeHandler);

  const customerEligibilityList: IRadio[] = [
    {
      label: t("marketing.promotion.customer_eligibility.all"),
      value: "all",
    },
    {
      label: t("marketing.promotion.customer_eligibility.specific_groups"),
      value: "specific_groups",
    },
    {
      label: t("marketing.promotion.customer_eligibility.specific_customers"),
      value: "specific_customers",
    },
  ];

  const customerEligibility = watch("customer_eligibility");

  return (
    <>
      <CustomizedBox margin={0}>
        <Box display={"flex"} mb={1} gap={0.3}>
          <Typography
            fontWeight={600}
            color={
              Boolean(errors.customer_group_name || errors.customer_specific)
                ? "error.main"
                : undefined
            }
          >
            {t("marketing.promotion.customer_eligibility.index")}
          </Typography>
          <Typography fontWeight={600} color={"error.main"}>
            *
          </Typography>
        </Box>

        <ControlledRadioGroup
          control={control}
          name="customer_eligibility"
          radioLists={customerEligibilityList}
          row
          disabled={disabled || isApplied}
          onChange={(_, value) => {
            if (value === "all") {
              if (
                getValues("group") ||
                getValues("customer_group_name") ||
                getValues("customer_specific")?.length > 0
              ) {
                openConfirmationHandler();
              } else {
                setValue("group", "");
                setValue("customer_group_name", "");
                setValue("customer_specific", []);
                setValue("customer_eligibility_snapshot", value);
              }
            } else if (value === "specific_groups") {
              if (getValues("customer_specific")?.length > 0) {
                openConfirmationHandler();
              } else {
                setValue("customer_specific", []);
                setValue("customer_eligibility_snapshot", value);
              }
            } else {
              if (getValues("group") || getValues("customer_group_name")) {
                openConfirmationHandler();
              } else {
                setValue("group", "");
                setValue("customer_group_name", "");
                setValue("customer_eligibility_snapshot", value);
              }
            }
          }}
        />
        {customerEligibility && customerEligibility === "specific_groups" && (
          <PromotionSpecificGroup />
        )}
        {customerEligibility &&
          customerEligibility === "specific_customers" && (
            <PromotionSpecificCustomer />
          )}
      </CustomizedBox>
      <ConfirmationModal
        title="การเปลี่ยนตัวเลือก"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนตัวเลือก"}</Typography>
            <Typography>{"ข้อมูลด้านล่างจะถูกล้างค่า"}</Typography>
          </Box>
        }
        open={confirmation}
        handleClose={cancelConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default PromotionCustomer;
