import { Fragment, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ClearIcon from "@mui/icons-material/Clear";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import Confirmation from "../../../../UI/Confirmation";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import SerialList from "../../../Inventory/GoodsIssue/SerialList";
import CustomizedAvatar from "../../../../Custom/CustomizedAvatar";
import ControlledSelect from "../../../../Controller/ControlledSelect";
import ControlledTextField from "../../../../Controller/ControlledTextField";
import TotalPostedQuantityCell from "../../../Inventory/GoodsIssue/TotalPostedQuantityCell";

import { useDisable } from "../../../../../hooks/use-disable";
import { Tracability } from "../../../../../generated/inventory";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import { IDeliveryOrder } from "../../../../../types/Logistic/deliveryOrder";
import { formatDate, formatDateTimeNoAMPM } from "../../../../../utils/Date";
import { useDeliveryOrderItemList } from "../../../../../hooks/Logistic/use-do-item-list";

type Props = {
  fields: FieldArrayWithId<IDeliveryOrder, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IDeliveryOrder, "trace_entry_list">;
};

const DeliveryOrderItemList = ({ fields, remove, update }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryOrder>();
  const { id } = useParams();
  const [disabled] = useDisable();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const disabledRemoveItem = ![undefined, null, "", "draft"].includes(
    status || ""
  );

  const headers = useDeliveryOrderItemList(disabledRemoveItem);

  return (
    <CustomizedBox
      maxWidth={1650}
      margin={!id || status === "draft" ? 0 : "1.5rem 0rem"}
    >
      <Typography color="primary.main" fontWeight={600} mb={2}>
        รายการสินค้า
      </Typography>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                    color={"primary.main"}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatDateTimeNoAMPM(trace.posted_date)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[2]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[3]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.sku_name}</Typography>
                    <Typography fontSize={14}>{trace.item_name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[4]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.document_item_qty || 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[5]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.posted_qty || 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[6]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {(trace.document_item_qty || 0) - (trace.posted_qty || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[7]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <>
                        {disabledRemoveItem || !trace.scanned_by ? (
                          <Typography fontSize={14}>{trace.qty}</Typography>
                        ) : (
                          <ControlledTextField
                            type="number"
                            control={control}
                            name={`trace_entry_list.${index}.qty`}
                            error={Boolean(
                              errors?.trace_entry_list?.[index]?.qty
                            )}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                // style: {
                                //   fontSize: 14,
                                // },
                              },
                            }}
                            FormHelperTextProps={{
                              style: { fontSize: "10px" },
                            }}
                            helperText={
                              trace.scanned_by
                                ? `จำนวนคงคลัง ${
                                    trace.stock_qty?.toString() || "-"
                                  }`
                                : undefined
                            }
                          />
                        )}
                      </>
                    ) : (
                      <TotalPostedQuantityCell nestedIndex={index} />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.uom?.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.serial_no}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[9]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      trace.all_bin_locations &&
                      trace.all_bin_locations.length > 1 &&
                      !disabled ? (
                        <ControlledSelect
                          control={control}
                          name={`trace_entry_list[${index}].source_bin_location_id`}
                          options={trace.all_bin_locations.map((bin) => ({
                            label: bin.name,
                            value: bin.id,
                          }))}
                          onChange={(e: any) => {
                            const { id, ...otherFields } = fields[index];
                            update(index, {
                              ...otherFields,
                              stock_qty:
                                trace.all_bin_locations &&
                                trace.all_bin_locations.find(
                                  (bin) => bin.id === e.target.value
                                ).stock_qty,
                            });
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          MenuProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          helperTextSize={10}
                        />
                      ) : (
                        <Typography fontSize={14}>
                          {trace.source_bin_location?.name}
                        </Typography>
                      )
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[10]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.scanned_by && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CustomizedAvatar
                          avatars={[
                            {
                              unique_id: trace.scanned_by.user_unique_id || "",
                              first_name: trace.scanned_by.first_name || "",
                              last_name: trace.scanned_by.last_name || "",
                              img_url: trace.scanned_by.img_url
                                ? trace.scanned_by.img_url[0]
                                : "",
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[11]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.barcode}</Typography>
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[12]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        <ControlledTextField
                          placeholder={t("sentence.remark")}
                          control={control}
                          name={`trace_entry_list.${index}.remark`}
                          viewMode={status === "finished"}
                          viewModeNoLabel
                        />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[13]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        {formatDate(trace.lot_date)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[14]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        {formatDate(trace.manufactured_date)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[15]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        {formatDate(trace.expired_date)}
                      </Typography>
                    )}
                  </TableCell>
                  {(!id || status === "draft") && (
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[16]?.width || 0
                          : 0) + 16
                      }
                    >
                      <IconButton
                        onClick={() => {
                          openConfirmationHandler();
                          setDeletedIndex(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                <SerialList nestedIndex={index} />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title="ยืนยันหากต้องการลบสินค้า"
        message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default DeliveryOrderItemList;
