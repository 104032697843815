import { GraphQLClient } from "graphql-request";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  GetRowIdParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import CheckboxAggridModal from "./CheckboxAggridModal";
import { AVAILABLE_PROMOTION_SETTINGS_AGGRID } from "../../../services/AgGrid/MarketingAgGrid";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import { priceNumberFormatter } from "../../../utils/Formatter/Global";

type CheckedAvailablePromotionSettingsModalProps = {
  showSelectPromotion: boolean;
  closePromotionTable: () => void;
  finishPromotionSelect: (data: any) => void;
  promotionUniqueIds: string[];
  setPromotionUniqueIds: Dispatch<SetStateAction<string[]>>;
  promotionUniqueIdsSnapshot: string[];
  setPromotionUniqueIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  branchId: number;
  customerId: number;
  totalPrice: number;
  itemList: any[];
  rowSelection?: "single" | "multiple";
  keyName?: string;
};

const CheckedAvailablePromotionSettingsModal = ({
  showSelectPromotion,
  closePromotionTable,
  finishPromotionSelect,
  promotionUniqueIds,
  setPromotionUniqueIds,
  promotionUniqueIdsSnapshot,
  setPromotionUniqueIdsSnapshot,
  branchId,
  customerId,
  totalPrice,
  itemList,
  rowSelection,
  keyName,
}: CheckedAvailablePromotionSettingsModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>();

  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("marketing.promotion.unique_id"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: t("marketing.promotion.index"),
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "coupon_code",
      headerName: t("marketing.promotion.coupon.code"),
      filter: false,
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "promotion_category",
      headerName: `${t("marketing.promotion.type.index")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "discount_product",
          "discount_total",
          "discount_order",
          "free_gift",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.type_short.${params.value}`),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        t(`marketing.promotion.type_short.${params.value}`),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount_per_item",
      headerName: t("marketing.promotion.discount_rate"),
      filter: "agNumberColumnFilter",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return priceNumberFormatter(parseFloat(params.value), 2);
        } else return "0.00";
      },
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
    },
    {
      field: "receive_type",
      headerName: `${t("marketing.promotion.receive_type.index")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["percent", "amount", "freebie"],
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.receive_type.table.short.${params.value}`),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        t(`marketing.promotion.receive_type.table.short.${params.value}`),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "redeem_item",
      headerName: t("marketing.promotion.type_short.free_gift"),
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value?.[0]?.item_name;
        } else return "-";
      },
      flex: 1,
      minWidth: 200,
    },
    //TODO freebie amount,
    // TODO Freebie uom
    {
      field: "priority",
      headerName: `${t("marketing.promotion.priority")}`,
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "purchase_condition",
      headerName: `${t("marketing.promotion.promotion_condition")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["all_items", "specific_items"],
        valueFormatter: (params: ValueFormatterParams) =>
          t(`marketing.promotion.purchase_condition.${params.value}`),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        t(`marketing.promotion.purchase_condition.${params.value}`),
      flex: 1,
      minWidth: 200,
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        start_date,
        end_date,
        created_date,
        created_by_object,
        priority,
        receive_type,
        last_updated_date,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        // start_date: dateFilterModel(start_date),
        // end_date: dateFilterModel(end_date),
        // created_date: dateFilterModel(created_date),
        // created_by: created_by_object,
        // priority: priority
        //   ? {
        //       filter: isNaN(parseInt(priority.filter))
        //         ? 0
        //         : parseInt(priority.filter),
        //       filterType: "number",
        //       type: "equals",
        //     }
        //   : undefined,
        // receive_type: receive_type
        //   ? {
        //       ...receive_type,
        //       values: receive_type.values.filter(
        //         (type: string) => type !== "freebie"
        //       ),
        //     }
        //   : undefined,
        // last_updated_date: dateFilterModel(last_updated_date),
      };
      try {
        const { PromotionSettingAvailableAggrid } = await graphQLClient.request(
          AVAILABLE_PROMOTION_SETTINGS_AGGRID,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
            customInput: {
              customer_id: customerId,
              branch_id: branchId,
              total_price: totalPrice,
              itemList,
            },
          }
        );
        params.success({
          rowData: PromotionSettingAvailableAggrid.results as any[],
          rowCount: PromotionSettingAvailableAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback((params: any) => {}, []);

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const getRowId = useCallback(
    (params: GetRowIdParams) => {
      return keyName ? params.data[keyName] : params.data.unique_id;
    },
    [keyName]
  );

  return (
    <CheckboxAggridModal
      modalTitle={t("setting.sales.channel")}
      btnTitle={t("button.choose")}
      gridRef={gridRef}
      height={665}
      columnDefs={columnDefs}
      rowSelection={rowSelection ?? "multiple"}
      onFinishEditing={finishPromotionSelect}
      modalIsOpen={showSelectPromotion}
      getRowId={getRowId}
      closeModal={closePromotionTable}
      selectedIds={promotionUniqueIds}
      setSelectedIds={setPromotionUniqueIds}
      idsSnapshot={promotionUniqueIdsSnapshot}
      setIdsSnapshot={setPromotionUniqueIdsSnapshot}
      keyName={keyName}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedAvailablePromotionSettingsModal;
