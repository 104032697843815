import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { ISelectOption } from "../../types/global";
import {
  ApprovalTemplateFindByDocumentTypeQuery,
  BranchesFindManyAggridQuery,
  WarehousesFindAllQuery,
  useApprovalTemplateFindByDocumentTypeQuery,
  useBranchesFindManyAggridQuery,
  useWarehousesFindAllQuery,
} from "../../generated/general";
import { branchInfoFromSessionStorage } from "../../utils/Global";

export const usePurchaseOption = (type?: string) => {
  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { branch_id, is_hq } = branchInfoFromSessionStorage();

  const { data: approvalTemplates, isLoading: isApprovalLoading } =
    useApprovalTemplateFindByDocumentTypeQuery<ApprovalTemplateFindByDocumentTypeQuery>(
      graphQLClientGeneral,
      {
        documentType: type || "",
      },
      {
        enabled: !!type,
      }
    );

  const { data: branches, isLoading: isBranchesLoading } =
    useBranchesFindManyAggridQuery<BranchesFindManyAggridQuery>(
      graphQLClientGeneral,
      {
        aggridInput: {
          startRow: 0,
          endRow: 100,
        },
      }
    );

  const { data: warehouse, isLoading: isWarehouseLoading } =
    useWarehousesFindAllQuery<WarehousesFindAllQuery>(graphQLClientGeneral);

  const branchesOptions: ISelectOption[] =
    branches?.BranchesFindManyAggrid.results.map((branch) => ({
      label: branch.is_hq
        ? `${branch.unique_id} - ${branch.name} (สำนักงานใหญ่)`
        : `${branch.unique_id} - ${branch.name}`,
      value: branch.id,
      id: branch.id,
    })) || [];

  const warehouseOptions: ISelectOption[] =
    warehouse?.WarehousesFindAll
      // .filter((warehouse) =>
      //   is_hq === "true"
      //     ? true
      //     : warehouse.branch_id === parseInt(branch_id ?? "0")
      // )
      .map((warehouse) => ({
        label: `${warehouse.unique_id} - ${warehouse.name}`,
        value: warehouse.id,
        id: warehouse.id,
      })) || [];

  const renderBranchOptions = (id: number) =>
    branchesOptions.find((option) => option.id === id)?.label;

  const renderWarehousesOptions = (id: number) =>
    warehouseOptions.find((option) => option.id === id)?.label;

  return {
    branchesOptions,
    warehouseOptions,
    renderBranchOptions,
    renderWarehousesOptions,
    approvalTemplates:
      approvalTemplates?.ApprovalTemplateFindByDocumentType || [],
    isLoading:
      isBranchesLoading || isWarehouseLoading || (type && isApprovalLoading),
  };
};
