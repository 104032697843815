import dayjs from "dayjs";
import { IItemGroup, IPromotion } from "../../../../types/Marketing/Promotion";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { COUPON_AGGRID } from "../../../../services/AgGrid/MarketingAgGrid";

export const itemGroupSchema: IItemGroup = {
  type: "",
  name: "",
};

export const promotionSchema: IPromotion = {
  unique_id: "",
  state_uuid: uuidv4(),
  name: "",
  description: "",
  start_date: dayjs(),
  end_date: dayjs(),
  promotion_category: "",
  status: "active",
  available_for: "erp",
  type: "all_promotions",
  use_auto: false,
  priority: undefined,
  purchase_condition: "all_items",
  is_limit_amount: false,
  limit_amount: undefined,
  is_item_group: false,
  item_group: [itemGroupSchema],
  is_item_condition: false,
  item_condition: [],
  receive_type: "amount",
  amount_per_item: undefined,
  redeem_item: [],
  coupon_type: "many_time",
  coupon_code: "",
  customer_eligibility: "all",
  customer_eligibility_snapshot: "all",
  group: "",
  customer_group_name: "",
  customer_specific: [],
  sales_channel_type: "all",
  sales_channel_type_snapshot: "all",
  branch_list: [],
  sales_channel_list: [],
  usage_limit_type: "no_limit",
  usage_limit: undefined,
  is_applied: false,
};

const promotionItemListValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุ")
    .required("กรุณาระบุ"),
});

const itemGroupValidation = Yup.object().shape({
  type: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
});

//find coupon from aggrid by state uuid
const getCouponList = async (value: string | undefined) => {
  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");
  try {
    const { CouponViewsAggrid } = await graphQLClient.request(COUPON_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 10,
        filterModel: {
          state_uuid: value
            ? {
                filterType: "text",
                type: "equals",
                filter: value,
              }
            : undefined,
        },
      },
    });

    if (CouponViewsAggrid.count > 0) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const promotionValidation = Yup.object()
  .shape({
    unique_id: Yup.string(),
    name: Yup.string().required("กรุณาระบุ"),
    start_date: Yup.date()
      .required("กรุณาระบุ")
      .max(Yup.ref("end_date"), "กรุณาระบุข้อมูลให้ถูกต้อง"),
    end_date: Yup.date().required("กรุณาระบุ"),
    status: Yup.string().required("กรุณาระบุ"),
    available_for: Yup.string().required("กรุณาระบุ"),
    type: Yup.string().required("กรุณาระบุ"),
    priority: Yup.number().typeError("กรุณาระบุ").required("กรุณาระบุ"),
    purchase_condition: Yup.string().required("กรุณาระบุ"),
    is_limit_amount: Yup.boolean(),
    limit_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .when("is_limit_amount", {
        is: true,
        then: (schema) => schema.positive("กรุณาระบุ").required("กรุณาระบุ"),
      }),
    is_item_group: Yup.boolean(),
    item_group: Yup.array().when("is_item_group", {
      is: true,
      then: (schema) => schema.of(itemGroupValidation),
    }),
    is_item_condition: Yup.boolean(),
    item_condition: Yup.array()
      .of(promotionItemListValidation)
      .when("is_item_condition", {
        is: true,
        then: (schema) => schema.min(1, "กรุณาระบุ"),
      }),
    receive_type: Yup.string().required("กรุณาระบุ"),
    amount_per_item: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .positive("กรุณาระบุ")
      .required("กรุณาระบุ"),
    redeem_item: Yup.array()
      .of(promotionItemListValidation)
      .min(1, "กรุณาเลือก"),
    coupon_type: Yup.string().required("กรุณาระบุ"),
    coupon_code: Yup.string().when("coupon_type", {
      is: "many_time",
      then: (schema) => schema.required("กรุณาระบุ"),
      otherwise: (schema) => schema.nullable(),
    }),
    // validate coupon list here
    state_uuid: Yup.string().when("coupon_type", {
      is: "one_time",
      then: (schema) =>
        schema.test(
          "is-not-empty",
          // error message
          "กรุณานำเข้าคูปอง",
          async (value) => {
            const isNotEmpty = await getCouponList(value);
            return isNotEmpty;
          }
        ),
    }),
    customer_eligibility: Yup.string().required("กรุณาระบุ"),
    group: Yup.string().when("customer_eligibility", {
      is: "specific_groups",
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
    customer_group_name: Yup.string().when("customer_eligibility", {
      is: "specific_groups",
      then: (schema) => schema.required("กรุณาระบุ"),
    }),
    customer_specific: Yup.array().when("customer_eligibility", {
      is: "specific_customers",
      then: (schema) => schema.min(1, "กรุณาเลือก"),
    }),
    sales_channel_type: Yup.string().required("กรุณาระบุ"),
    branch_list: Yup.array().when("sales_channel_type", {
      is: "specific_sales",
      then: (schema) => schema.min(1, "กรุณาเลือก"),
    }),
    sales_channel_list: Yup.array().when("sales_channel_type", {
      is: "specific_channels",
      then: (schema) => schema.min(1, "กรุณาเลือก"),
    }),
    usage_limit_type: Yup.string().required(""),
    usage_limit: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .when("usage_limit_type", {
        is: "limited",
        then: (schema) => schema.positive("กรุณาระบุ").required("กรุณาระบุ"),
      }),
  })
  .test(
    "at-least-one-required", // <-- Add the test identifier (type) here
    'At least one of is_limit_amount, is_item_group, or is_item_condition must be true when purchase_condition is "item_condition"',
    function (value) {
      const {
        purchase_condition,
        is_limit_amount,
        is_item_group,
        is_item_condition,
      } = value;

      if (purchase_condition === "specific_items") {
        if (!(is_limit_amount || is_item_group || is_item_condition)) {
          return this.createError({
            path: "purchase_condition", // Attach the error to a specific key
            message: "กรุณาเลือกอย่างน้อย 1 รายการ",
          });
        }
      }
      return true;
    }
  );
