import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { IRadio } from "../../../../../types/global";
import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import { Box, Typography } from "@mui/material";
import ControlledRadioGroup from "../../../../Controller/ControlledRadioGroup";
import { useDisable } from "../../../../../hooks/use-disable";
import PromotionSelectBranch from "./SelectBranch";
import PromotionSelectSalesChannel from "./SelectSalesChannel";
import { useConfirmation } from "../../../../../hooks/use-confirmation";
import ConfirmationModal from "../../../../UI/Modal/ConfirmationModal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const PromotionSalesChannel = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IPromotion>();
  const [disabled] = useDisable();

  const watchIsApplied = watch("is_applied");
  const isApplied = Boolean(watchIsApplied);

  const cancelConfirmationHandler = () => {
    setValue(
      "sales_channel_type",
      getValues("sales_channel_type_snapshot") || "all"
    );
    closeConfirmationHandler();
  };

  const confirmSalesChannelTypeChangeHandler = () => {
    const newSalesChannelType = getValues("sales_channel_type");
    switch (newSalesChannelType) {
      case "all": {
        setValue("branch_list", []);
        setValue("sales_channel_list", []);
        break;
      }
      case "specific_sales": {
        setValue("sales_channel_list", []);
        break;
      }
      default:
        setValue("branch_list", []);
    }
    setValue("sales_channel_type_snapshot", newSalesChannelType);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(confirmSalesChannelTypeChangeHandler);

  const salesChannelTypeList: IRadio[] = [
    {
      label: t("marketing.promotion.sales_channel_type.all"),
      value: "all",
    },
    {
      label: t("marketing.promotion.sales_channel_type.specific_sales"),
      value: "specific_sales",
    },
    {
      label: t("marketing.promotion.sales_channel_type.specific_channels"),
      value: "specific_channels",
    },
  ];

  const salesChannelType = watch("sales_channel_type");

  return (
    <>
      <CustomizedBox>
        <Box display="flex" mb={1} gap={0.3}>
          <Typography
            fontWeight={600}
            color={
              Boolean(errors.branch_list || errors.sales_channel_list)
                ? "error.main"
                : undefined
            }
          >
            {t("marketing.promotion.sales_channel_type.index")}
          </Typography>
          <Typography fontWeight={600} color={"error.main"}>
            *
          </Typography>
        </Box>

        <ControlledRadioGroup
          control={control}
          name="sales_channel_type"
          radioLists={salesChannelTypeList}
          row
          disabled={disabled || isApplied}
          onChange={(_, value) => {
            if (value === "all") {
              if (
                getValues("branch_list")?.length > 0 ||
                getValues("sales_channel_list")?.length > 0
              ) {
                openConfirmationHandler();
              } else {
                setValue("branch_list", []);
                setValue("sales_channel_list", []);
                setValue("sales_channel_type_snapshot", value);
              }
            } else if (value === "specific_sales") {
              if (getValues("sales_channel_list")?.length > 0) {
                openConfirmationHandler();
              } else {
                setValue("sales_channel_list", []);
                setValue("sales_channel_type_snapshot", value);
              }
            } else {
              if (getValues("branch_list")?.length > 0) {
                openConfirmationHandler();
              } else {
                setValue("branch_list", []);
                setValue("sales_channel_type_snapshot", value);
              }
            }
          }}
        />
        {salesChannelType && salesChannelType === "specific_channels" && (
          <PromotionSelectSalesChannel />
        )}
        {salesChannelType && salesChannelType === "specific_sales" && (
          <PromotionSelectBranch />
        )}
      </CustomizedBox>
      <ConfirmationModal
        title="การเปลี่ยนตัวเลือก"
        message={
          <Box textAlign="center" my={1} mb={2}>
            <ErrorOutlineOutlinedIcon
              sx={{
                fontSize: "4rem",
                marginTop: 1,
                color: "gray.light",
              }}
            />
            <Typography>{"หากเปลี่ยนตัวเลือก"}</Typography>
            <Typography>{"ข้อมูลด้านล่างจะถูกล้างค่า"}</Typography>
          </Box>
        }
        open={confirmation}
        handleClose={cancelConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default PromotionSalesChannel;
