import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { IPromotion } from "../../../../../types/Marketing/Promotion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PromotionSelectSalesChannelTable from "../../../../Table/Marketing/Promotion/SelectSalesChannel";
import CustomizedButton from "../../../../Custom/CustomizedButton";
import { useModal } from "../../../../../hooks/use-modal";
import CheckedSalesChannelModal from "../../../../UI/Modal/CheckedSalesChannelModal";
import { Box, FormHelperText } from "@mui/material";
import { useDisable } from "../../../../../hooks/use-disable";

const PromotionSelectSalesChannel = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromotion>();

  const watchIsApplied = useWatch({ control, name: "is_applied" });
  const isApplied = Boolean(watchIsApplied);

  const { t } = useTranslation();
  const [disabled] = useDisable();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "sales_channel_list",
  });

  const [salesChannelIds, setSalesChannelIds] = useState<string[]>([]);
  const [salesChannelIdsSnapshot, setSalesChannelIdsSnapshot] = useState<
    string[]
  >([]);

  const { modal, closeModalHandler, openModalHandler } = useModal();

  const addSalesChannelHandler = (data: any) => {
    replace(data);
    closeModalHandler();
  };

  const onRemoveHandler = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      const uniqueIdList = fields.map(({ unique_id }) => unique_id);
      setSalesChannelIds(uniqueIdList);
      setSalesChannelIdsSnapshot(uniqueIdList);
    } else {
      setSalesChannelIds([]);
      setSalesChannelIdsSnapshot([]);
    }
  }, [fields]);

  return (
    <>
      <Box mt={1}>
        <PromotionSelectSalesChannelTable
          rowData={fields}
          onRemoveHandler={onRemoveHandler}
        />
        {!disabled && !isApplied && (
          <CustomizedButton
            variant="outlined"
            title={`${t("button.add")}${t("setting.sales.channel")}`}
            onClick={openModalHandler}
            addIcon
          />
        )}
        {Boolean(errors.sales_channel_list) && (
          <FormHelperText error sx={{ mt: 1 }}>
            {errors.sales_channel_list?.message}
          </FormHelperText>
        )}
      </Box>
      <CheckedSalesChannelModal
        salesChannelIds={salesChannelIds}
        salesChannelIdsSnapshot={salesChannelIdsSnapshot}
        setSalesChannelIds={setSalesChannelIds}
        setSalesChannelIdsSnapshot={setSalesChannelIdsSnapshot}
        showSelectSalesChannel={modal}
        rowSelection="multiple"
        closeSalesChannelTable={closeModalHandler}
        finishSalesChannelSelect={addSalesChannelHandler}
      />
    </>
  );
};

export default PromotionSelectSalesChannel;
