import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDisable } from "../../../hooks/use-disable";
import { useActivityLog } from "../../../hooks/use-activity-log";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { Stack } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import LogisticFooter from "../../../components/Form/Logistic/Footer";
import CustomerInfo from "../../../components/Form/Logistic/CustomerInfo";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import AddressInfo from "../../../components/Form/Logistic/CustomerInfo/AddressInfo";
import ScanBarcode from "../../../components/Form/Logistic/DeliveryOrder/ScanBarcode";
import DeliveryInfo from "../../../components/Form/Logistic/DeliveryInfo/DeliveryInfo";
import DeliveryOrderHeader from "../../../components/Form/Logistic/DeliveryOrder/Header";

import { IDeliveryOrder } from "../../../types/Logistic/deliveryOrder";
import DeliveryOrderItemList from "../../../components/Table/Logistic/DeliveryOrder/ItemList/DeliveryOrderItemList";
import {
  ActivityLogDocumentType,
  ActivityType,
  DeliveryOrderCreateInput,
  DeliveryOrderFindUniqueQuery,
  DeliveryOrderUpdateInput,
  useDeliveryOrderCreateMutation,
  useDeliveryOrderFindUniqueQuery,
  useDeliveryOrderUpdateMutation,
} from "../../../generated/logistic";
import {
  deliveryOrderQueryFormatter,
  deliveryOrderCreatePayloadFormatter,
  deliveryOrderUpdatePayloadFormatter,
} from "../../../utils/Formatter/Logistic/DeliveryOrder";
import { errorMessageFormatter } from "../../../utils/Global";
import LoadingUI from "../../../components/UI/LoadingUI";
import {
  ItemSkuQtysQuery,
  useItemSkuQtysQuery,
} from "../../../generated/inventory";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const documentType = "delivery_order";
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useDisable();
  const { createActivityLog } = useActivityLog();
  const [barcodeList, setBarcodeList] = useState<any[]>([]);

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClient = createGraphQLClientWithMiddleware("logistic");
  const graphQLClientWms = createGraphQLClientWithMiddleware("wms");

  const {
    control,
    getValues,
    reset,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useFormContext<IDeliveryOrder>();

  const {
    fields,
    append,
    remove,
    update: traceUpdate,
  } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const status = watch("aggrid_status");

  const disabledField = !["", "draft"].includes(status || "");

  const { data, isLoading, isSuccess, refetch } =
    useDeliveryOrderFindUniqueQuery<DeliveryOrderFindUniqueQuery>(
      graphQLClient,
      {
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
      },
      {
        enabled: !!id,
        cacheTime: 0,
      }
    );

  const {
    data: allSkuQtys,
    isLoading: isAllSkuLoading,
    isSuccess: isSkuSuccess,
  } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWms,
    {
      findManyInput: {
        where: {
          barcode: {
            in: barcodeList,
          },
        },
      },
    },
    {
      enabled: barcodeList.length > 0,
    }
  );

  useEffect(() => {
    if (id && isSuccess) {
      const { DeliveryOrderFindUnique } = data;

      const getData = async () => {
        const traceEntryList = DeliveryOrderFindUnique?.trace_entry_list;
        const allBarcode =
          traceEntryList?.map((trace: any) => trace?.barcode) || [];
        setBarcodeList(allBarcode);

        const formatted = await deliveryOrderQueryFormatter(
          DeliveryOrderFindUnique,
          allSkuQtys
        );

        reset(formatted);
      };

      getData();
    }
  }, [data, allSkuQtys, isSuccess, isSkuSuccess, reset]);

  useEffect(() => {
    if (id && status !== "draft") {
      setDisabled(true);
    }
    return () => {
      setDisabled(false);
    };
  }, [id, setDisabled, status]);

  useEffect(() => {
    if (state) {
      const { copied_unique_id, copied_id, ...otherState } = state;
      reset({
        ...otherState,
        created_date: dayjs(),
        issue_date: dayjs(),
        delivery_date: dayjs(),
        exported_date: dayjs(),
      });
    }
  }, [reset, state]);

  const { mutateAsync: create, isLoading: isCreating } =
    useDeliveryOrderCreateMutation<Error>(graphQLClient);

  const { mutateAsync: update, isLoading: isUpdating } =
    useDeliveryOrderUpdateMutation<Error>(graphQLClient);

  const draftHandler = async (data: IDeliveryOrder) => {
    if (!id) {
      try {
        const formatData = deliveryOrderCreatePayloadFormatter(data, "draft");
        const { DeliveryOrderCreate } = await create({
          input: formatData as DeliveryOrderCreateInput,
        });
        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/logistic/delivery-order/${DeliveryOrderCreate.id}`);

        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.DeliveryOrder,
          reference_id: DeliveryOrderCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "draft",
          },
        });
      } catch (error) {
        const formatError = errorMessageFormatter(error);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    } else {
      try {
        const formatData = await deliveryOrderUpdatePayloadFormatter(
          data,
          "draft"
        );
        const { DeliveryOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as DeliveryOrderUpdateInput,
        });

        enqueueSnackbar(`${t("button.save_draft")}สำเร็จ`, {
          variant: "success",
        });

        await refetch();

        await createActivityLog({
          activity_type: ActivityType.Edit,
          document_type: ActivityLogDocumentType.DeliveryOrder,
          reference_id: DeliveryOrderUpdate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Edit,
          },
        });
      } catch (error) {
        const formatError = errorMessageFormatter(error);
        enqueueSnackbar(formatError || `${t("button.save_draft")}ไม่สำเร็จ`, {
          variant: "error",
        });
      }
    }
  };

  const issuedHandler = async (data: IDeliveryOrder) => {
    if (!id) {
      try {
        const formatData = deliveryOrderCreatePayloadFormatter(data, "issued");
        const { DeliveryOrderCreate } = await create({
          input: formatData as DeliveryOrderCreateInput,
        });
        enqueueSnackbar(`${t("inventory.goods_issue.index")}สำเร็จ`, {
          variant: "success",
        });
        navigate(`/logistic/delivery-order/${DeliveryOrderCreate.id}`);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.DeliveryOrder,
          reference_id: DeliveryOrderCreate?.id,
          activity_detail: {
            secondary_operation: ActivityType.Create,
            curr_status: "issued",
          },
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(
          formatError || `${t("inventory.goods_issue.index")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    } else {
      try {
        const formatData = await deliveryOrderUpdatePayloadFormatter(
          data,
          "issued"
        );
        const { DeliveryOrderUpdate } = await update({
          uniqueInput: {
            id: id ? parseInt(id) : undefined,
          },
          input: formatData as DeliveryOrderUpdateInput,
        });
        await refetch();
        const formattedDirtyFields = Object.keys(dirtyFields);
        await createActivityLog({
          activity_type: ActivityType.StatusChange,
          document_type: ActivityLogDocumentType.DeliveryOrder,
          reference_id: DeliveryOrderUpdate?.id,
          activity_detail: {
            secondary_operation:
              formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
            prev_status: data.aggrid_status,
            curr_status: "issued",
          },
        });
        enqueueSnackbar(`${t("inventory.goods_issue.index")}สำเร็จ`, {
          variant: "success",
        });
      } catch (err) {
        const formatError = errorMessageFormatter(err);
        enqueueSnackbar(
          formatError || `${t("inventory.goods_issue.index")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const inTransitHandler = async (data: IDeliveryOrder) => {};

  const editHandler = async (data: IDeliveryOrder) => {
    try {
      const formatData = await deliveryOrderUpdatePayloadFormatter(
        data,
        data?.main_status || ""
      );

      const { DeliveryOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: formatData as DeliveryOrderUpdateInput,
      });

      enqueueSnackbar(`${t("sentence.edit")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);

      await createActivityLog({
        activity_type: ActivityType.Edit,
        document_type: ActivityLogDocumentType.DeliveryOrder,
        reference_id: DeliveryOrderUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
        },
      });
      setDisabled(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("sentence.edit")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const cancelHandler = async () => {
    try {
      const data = getValues();
      const flag_status =
        data?.flag_status && data.flag_status.length > 0
          ? data.flag_status.includes("cancelled")
            ? [...data.flag_status]
            : [...data.flag_status, "cancelled"]
          : ["cancelled"];

      const formatData = await deliveryOrderUpdatePayloadFormatter(
        data,
        data?.main_status || ""
      );

      const { DeliveryOrderUpdate } = await update({
        uniqueInput: {
          id: id ? parseInt(id) : undefined,
        },
        input: {
          reference_document_list: data.reference_document_list,
          branch_id: data.branch_id,
          flag_status: flag_status,
        } as DeliveryOrderUpdateInput,
      });

      enqueueSnackbar(`${t("button.cancel")}สำเร็จ`, {
        variant: "success",
      });

      await refetch();

      const formattedDirtyFields = Object.keys(dirtyFields);
      await createActivityLog({
        activity_type: ActivityType.StatusChange,
        document_type: ActivityLogDocumentType.DeliveryOrder,
        reference_id: DeliveryOrderUpdate?.id,
        activity_detail: {
          secondary_operation:
            formattedDirtyFields?.length > 0 ? ActivityType.Edit : undefined,
          prev_status: data.aggrid_status,
          curr_status: "cancelled",
          updated_fields:
            formattedDirtyFields?.length > 0 ? formattedDirtyFields : undefined,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`${t("button.cancel")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const editClickHandler = () => {
    setDisabled(false);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  const renderButton = (status: string | undefined) => {
    switch (status) {
      case "draft":
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("inventory.goods_issue.index")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(issuedHandler)}
            />
          </>
        );
      case "issued":
        if (!disabled) {
          return (
            <>
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={cancelEditHandler}
                // disabled={isUpdating}
              />
              <CustomizedButton
                title={t("button.save")}
                variant="contained"
                onClick={handleSubmit(editHandler)}
                // disabled={isUpdating}
              />
            </>
          );
        } else {
          return (
            <CustomizedButton
              variant="contained"
              title={t("status.delivering")}
              onClick={handleSubmit(issuedHandler)}
              // disabled={isUpdating}
            />
          );
        }
      case "accepted":
      case "cancelled":
      case "approved":
      case "finished":
        return;
      default:
        return (
          <>
            <CustomizedButton
              variant="outlined"
              title={t("button.save_draft")}
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(draftHandler)}
            />
            <CustomizedButton
              title={t("inventory.goods_issue.index")}
              variant="contained"
              disabled={isCreating || isUpdating}
              onClick={handleSubmit(issuedHandler)}
            />
          </>
        );
    }
  };

  if (id && (isLoading || isCreating || isUpdating)) {
    return <LoadingUI />;
  }

  return (
    <>
      <DeliveryOrderHeader
        editClickHandler={editClickHandler}
        cancelHandler={cancelHandler}
        disabledField={disabledField}
      />
      <DeliveryInfo />
      <CustomerInfo disabledField={disabledField} />
      <AddressInfo documentType={documentType} disabledField={disabledField} />
      <ScanBarcode fields={fields} update={traceUpdate} />
      <DeliveryOrderItemList
        fields={fields}
        update={traceUpdate}
        remove={remove}
      />
      <LogisticFooter documentType={documentType} />
      <BottomNavbar>
        {(!disabled || status === "issued") && (
          <Stack direction="row" spacing={1} alignItems="center">
            {renderButton(status)}
          </Stack>
        )}
      </BottomNavbar>
    </>
  );
};

export default DocumentInfoTab;
