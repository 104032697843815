import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePromotionColumnDefs } from "./columnDefs";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import AgGrid from "../../../UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PROMOTION_AGGRID } from "../../../../services/AgGrid/MarketingAgGrid";

interface Props {
  isFilter?: string | null;
}

const PromotionTable = ({ isFilter }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = usePromotionColumnDefs();

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("marketing");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        start_date,
        end_date,
        created_date,
        created_by_object,
        priority,
        receive_type,
        last_updated_date,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        start_date: dateFilterModel(start_date),
        end_date: dateFilterModel(end_date),
        created_date: dateFilterModel(created_date),
        created_by: created_by_object,
        priority: priority
          ? {
              filter: isNaN(parseInt(priority.filter))
                ? 0
                : parseInt(priority.filter),
              filterType: "number",
              type: "equals",
            }
          : undefined,
        receive_type: receive_type
          ? {
              ...receive_type,
              values: receive_type.values.filter(
                (type: string) => type !== "freebie"
              ),
            }
          : undefined,
        last_updated_date: dateFilterModel(last_updated_date),
      };
      try {
        const { PromotionSettingViewsAggrid } =
          await graphQLClientWithHeaderSales.request(PROMOTION_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: PromotionSettingViewsAggrid.results as any[],
          rowCount: PromotionSettingViewsAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("status");
      switch (isFilter) {
        case "active":
          instance?.setModel({ values: ["active"] });
          break;
        case "inactive":
          instance?.setModel({ values: ["inactive"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const navigateHandler = (category: string) => {
    switch (category) {
      //TODO: other differences category
      case "discount_product":
        return "discount-product";
    }
  };

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(
      `/marketing/promotion/${navigateHandler(
        params.data.promotion_category
      )}/${encodeURIComponent(params.data.id)}`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
    />
  );
};

export default PromotionTable;
